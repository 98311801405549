import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable, observable } from 'rxjs';
import { API_CONSTANTS } from '@common/constant/ApiConstants';

@Injectable()
export class ClientSubClientService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, searchKeyword?: number | string): Observable<any> {
		let url = `${API_CONSTANTS.USERS_URL}/${id}/sub-users`;
		let params = [];

		if (searchKeyword) {
			params.push(`filter[q]=${searchKeyword}`);
		}

		if (params.length > 0) {
			url += `?${params.join('&')}`;
		}

		return this._customHttpService.get(url);
	}

	activate(id, subclient_id): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/sub-clients/' +
				subclient_id +
				'/activate',
			''
		);
	}

	deactivate(id, subclient_id): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/sub-clients/' +
				subclient_id +
				'/deactivate',
			''
		);
	}

	permissions(id, subclient_id, oData): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/sub-clients/' +
				subclient_id +
				'/permissions',
			oData
		);
	}

	getPermissions(id, subclient_id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/sub-clients/' +
				subclient_id +
				'/permissions'
		);
	}

	getAccountActivities(id, pageNumber, searchQuery = '', sortType = '', showUnresolved = 0, directMessage = 0, 
		accountActions = 0, projectMessages = 0, projectMessageId = ''): Observable<any> {
		
		let queryParams = [`page=${pageNumber}`];
	
		if (directMessage || accountActions || projectMessages || projectMessageId) {
			queryParams.push(`filter[messages]=1`);
		}
	
		if (searchQuery) queryParams.push(`filter[q]=${searchQuery}`);
		if (sortType) queryParams.push(`sort=${sortType}`);
		if (showUnresolved) queryParams.push(`filter[show_unresolved]=${showUnresolved}`);
		if (directMessage) queryParams.push(`filter[direct_messages]=${directMessage}`);
		if (accountActions) queryParams.push(`filter[account_actions]=${accountActions}`);
		if (projectMessages) queryParams.push(`filter[project_messages]=${projectMessages}`);
		if (projectMessageId) queryParams.push(`filter[project_id]=${projectMessageId}`);
	
		const queryString = queryParams.join('&');
	
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${id}/account/activities?${queryString}`
		);
	}

	delete(id, subclient_id): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.USERS_URL + '/' + id + '/sub-clients/' + subclient_id
		);
	}

	storeRecentLink(id, oData) {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/' + id + '/user-preferences',
			oData
		);
	}

	// users/{userId}/user-preferences
	getRecentLink(id, oParams) {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/user-preferences' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	canViewProjects(permissions) {
		return (
			permissions?.can_view_projects ||
			permissions?.can_manage_all_projects ||
			permissions?.can_view_manage_own_projects
		);
	}

	canManageOwnProjects(permissions) {
		return permissions?.can_manage_own_projects;
	}

	canManageAllProjects(permissions) {
		return permissions?.can_manage_all_projects;
	}

	canViewManageOwnProjects(permissions) {
		return permissions?.can_view_manage_own_projects;
	}

	canViewOwnProjects(permissions) {
		return permissions?.can_view_manage_own_projects;
	}

	canViewAllProjects(permissions) {
		return permissions?.can_view_projects;
	}
}
