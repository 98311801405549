<div #createOverlay (scroll)="$event.stopPropagation()" *ngIf="showOverlay && canCreateProject" [style.z-index]="1000"
	 class=" overlay-position px-md-5 py-3 px-3 position-absolute">
	<app-create-project-overlay (onClose)="onCloseCheck($event)"></app-create-project-overlay>
</div>

<div *ngIf="showOverlayTrigger && canCreateProject"
	 #createProjectButton
	 class="create-project-position position-fixed p-4">
	<div draggable="false" (click)="openCreateProjectOverlay()"
		 [class.bg-primary]="!isMinimised"
		 [class.bg-purple]="isMinimised"
		 (touchstart)="onTouchStart($event)"
		 (touchend)="onTouchEnd($event)"
		 (touchcancel)="onTouchEnd($event)"
		 (mousedown)="onMouseDown($event)"
		 (mouseup)="onMouseUp($event)" (mouseleave)="onMouseUp($event)"
		 class="create-project clickable p-md-3 p-2 rounded-circle d-flex justify-content-center align-items-center ">
		<div draggable="false" [style.width.px]="50" [style.height.px]="50" class="d-flex justify-content-center align-items-center">
			<ng-container *ngIf="isMinimised; else addImage">
				<img draggable="false" alt="1" class="min_draft w-75 w-md-100" src="assets/img/project-overlay/draft_min.svg">
			</ng-container>
			<ng-template #addImage>
				<img draggable="false" alt="Create New Project" src="assets/img/project-overlay/add.svg">
			</ng-template>
		</div>
	</div>
</div>