<div class="wrapper white-bg">
	<app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
	<div class="main_wrapper">
		<!-- START card-->
		<div class="login_title login_title_top font-36">Login</div>
		<div class="card-main margin-top-4-rem">
			<div class="card-body border rounded">
				<div *ngIf="oError">
					<alert
						*ngIf="oError?.status"
						dismissible="true"
						type="danger">
						{{ oError.error.message }}
					</alert>
				</div>
				<form (submit)="submitForm($event, valForm.value)" [formGroup]="valForm" class="form-validate form-main mb-3" name="loginForm" novalidate="" role="form">
					<!-- Email field -->
					<div class="form-group green-input">
						<label class="label-required" for="email">Email</label>
						<span class="text-danger">*</span>
						<div class="input-group with-focus" [class.valid-border]="valForm.get('email').valid && valForm.get('email').dirty">
							<input class="form-control border-right-0" formControlName="email" name="email" required="" type="email" placeholder="Enter your email address" />
							<div class="input-group-append">
								<span class="input-group-text text-muted bg-white">
									<img src="../../../../assets/img/envelope-fill.svg" alt="envelope">
								</span>
							</div>
						</div>
						<!-- Modified validation message conditions -->
						<div *ngIf="submitted && valForm.controls['email'].hasError('required')" class="text-danger">
							This field is required
						</div>
						<div *ngIf="submitted && valForm.controls['email'].hasError('email') && valForm.controls['email'].dirty" class="text-danger">
							This field must be a valid email address
						</div>
					</div>
					<!-- Password field -->
					<div class="form-group green-input">
						<label class="label-required" for="password">Password</label>
						<span class="text-danger">*</span>
						<div class="input-group with-focus" [class.valid-border]="valForm.get('password').valid && valForm.get('password').dirty">
							<input class="form-control border-right-0" formControlName="password" name="password" required="" type="password" placeholder="********" />
							<div class="input-group-append">
								<span class="input-group-text text-muted bg-white">
									<img src="../../../../assets/img/key.svg" alt="key">
								</span>
							</div>
						</div>
						<div *ngIf="submitted && valForm.controls['password'].hasError('required')" class="text-danger">
							This field is required
						</div>
					</div>
					<div
						class="d-flex justify-content-between align-items-center pt-3">
						<div>
							<a
								(click)="
									this._analyticsService.setRouteData(7, 13)
								"
								class="text-no-decoration text-purple font-12"
								[routerLink]="'/password/reset'">
								Forgot your password?
							</a>
						</div>
						<button
							class="btn purple-btn text-white py-2"
							(click)="
								this._analyticsService.setRouteData(16, 31)
							"
							type="submit"
							id="loginBtn">
							<p class="mb-0 px-2">Login</p>
						</button>
					</div>
					<div *ngIf="!isWhiteLabelledUser" class="d-flex flex-column align-items-center mt-4">
						<div class="continue-btns mt-3 d-flex flex-row py-2 pl-3 pr-4 justify-content-between align-items-center clickable"
							(click)="loginWithSocials('google')">
							<div>
								<img src="assets/img/google-icon.svg" alt="">
							</div>
							<div class="continue-text" [ngClass]="isGoogleLoading ? 'w-100' : ''">
								<ng-container *ngIf="!isGoogleLoading"> <!-- Show the text when not loading -->
									Continue with Google
								</ng-container>
								<ng-container *ngIf="isGoogleLoading"> <!-- Show the loader when loading -->
									<div class="spinner-border text-primary" role="status">
									  <span class="sr-only">Loading...</span>
									</div>
								</ng-container>
							</div>
						</div>
						<div class="mt-3 continue-btns d-flex flex-row py-2 pl-3 pr-4 justify-content-between align-items-center clickable"
							(click)="loginWithSocials('microsoft')">
							<div>
								<img src="../../../../assets/img/microsoft-icon.svg" alt="">
							</div>
							<div class="continue-text" [ngClass]="isMicrosoftLoading ? 'w-100' : ''">
								<ng-container *ngIf="!isMicrosoftLoading"> <!-- Show the text when not loading -->
									Continue with Microsoft
								</ng-container>
								<ng-container *ngIf="isMicrosoftLoading"> <!-- Show the loader when loading -->
									<div class="spinner-border text-primary" role="status">
									  <span class="sr-only">Loading...</span>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</form>
				<ng-container *ngIf="!isWhiteLabelledUser">
					<hr>
					<div class="row mx-0 justify-content-center">
						<div class="d-flex justify-content-center mr-2 text-muted">
							<p class="font-12">Don't have an account?</p>
						</div>
						<div class="d-flex justify-content-center">
							<a
								(click)="this._analyticsService.setRouteData(2, 7)"
								class="signup-link"
								*ngIf="!referralSource"
								[routerLink]="'/signup'">
								Sign Up
							</a>
							<a
								(click)="this._analyticsService.setRouteData(2, 7)"
								class="signup-link"
								*ngIf="referralSource"
								[queryParams]="{ r: referralSource }"
								[routerLink]="'/signup'">
								Sign Up
							</a>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!isWhiteLabelledUser" class="p-3 footer white-bg">
	<span>&copy;</span>
	<span>{{ _settingsService.getAppSetting('year') }}</span>
	<span class="mx-2">-</span>
	<span>{{ _settingsService.getAppSetting('name') }}</span>
</div>