import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { SignupService } from '@services/signup/signup.service';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { WhiteLabelledUser } from '@services/interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-password-reset-token',
	templateUrl: './password-reset-token.component.html',
	styleUrls: ['./password-reset-token.component.scss'],
})
export class PasswordResetTokenComponent implements OnInit, OnDestroy {
	tokenExist = false;
	token = '';
	valForm: FormGroup;
	passwordForm: FormGroup;
	oError: object = {
		status: '',
	};
	serverStatusCode: number;
	serverMessage: string;
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();
	whiteLabelledUser: WhiteLabelledUser;
	destroyed$ = new Subject<void>();

	constructor(
		public _settingsService: SettingsService,
		public _signupService: SignupService,
		public _formBuilder: FormBuilder,
		private _router: Router,
		public _activatedRoute: ActivatedRoute,
		public _toasterService: ToasterService,
		public _whiteLabelService: WhiteLabelService,
	) {
		this._activatedRoute.params.subscribe((val) => {
			this.token = this._activatedRoute.snapshot.paramMap.get('token');
		});
	}

	ngOnInit() {
		if(this.isWhiteLabelledUser) {
			this._whiteLabelService.getWhiteLabelledUser$()
				.pipe(takeUntil(this.destroyed$))
				.subscribe((user) => {
				this.whiteLabelledUser = user;
			})
		}
		this.getToken();
	}

	initForm() {
		let password = new FormControl(
			'',
			Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(16),
			])
		);
		let certainPassword = new FormControl('', [Validators.required]);

		this.passwordForm = this._formBuilder.group(
			{
				password: password,
				confirm_password: certainPassword,
			},
			{
				validator: FlocksyValidator.equalTo(
					'password',
					'confirm_password'
				),
			}
		);

		this.valForm = this._formBuilder.group({
			passwordGroup: this.passwordForm,
		});
	}

	getToken(): void {
		this._signupService.checkResetToken(this.token).subscribe(
			(data) => {
				this.token = data.data.token;
				this.tokenExist = true;
				this.initForm();
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		for (let c in this.passwordForm.controls) {
			this.passwordForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}

		var token_data = {
			token: this.token,
		};

		Object.assign(this.valForm.value, token_data);
		Object.assign(this.valForm.value, this.valForm.value.passwordGroup);

		this._signupService.storeResetToken(this.valForm.value).subscribe(
			(data) => {
				this.valForm.reset();
				this._toasterService.pop('success', data.message);
				this.serverStatusCode = data.status;
				this.serverMessage = data.message;
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
