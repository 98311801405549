<div class="wrapper white-bg">
	<app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
	<div class="main_wrapper">
		<!-- START card-->
		<div class="login_title font-36">Reset Password</div>

		<div class="card-main margin-top-4-rem flex-column align-items-center">
			<div class="card-body border rounded">
				<p class="m_b_0 font-14">Forgot your password?</p>
				<p class="text-muted font-14">
					Don't worry! Just enter your email and we'll help you out.
				</p>
				<form
					(submit)="submitForm($event)"
					*ngIf="valForm"
					[formGroup]="valForm"
					class="form-validate form-main justify-content-between align-items-center"
					name="recoverForm"
					novalidate=""
					role="form">
					<div class="form-group w-100 mb-4 green-input">
						<label class="label-required font-14" for="email">Email</label>
						<span class="text-danger">*</span>
						<div class="input-group with-focus" [class.valid-border]="valForm.get('email').valid && valForm.get('email').dirty">
							<input
								autocomplete="off"
								class="form-control border-right-0"
								formControlName="email"
								name="email"
								placeholder="Enter your email address"
								type="email" />
							<div class="input-group-append" >
								<span
									class="input-group-text text-muted bg-white">
									<img src="../../../../assets/img/envelope-fill.svg" alt="envelope">
								</span>
							</div>
						</div>
						<div *ngIf="submitted && valForm.controls['email'].hasError('required')" class="text-danger">
							This field is required
						</div>
						<div *ngIf="submitted && valForm.controls['email'].hasError('email') && valForm.controls['email'].dirty" class="text-danger">
							This field must be a valid email address
						</div>
					</div>
					<div class="d-flex justify-content-end">
						<button
							class="btn purple-btn text-white py-2"
							type="submit">
							<p class="mb-0 px-2">Reset Password</p>
						</button>
					</div>
				</form>
				<ng-container *ngIf="!isWhiteLabelledUser">
					<hr>
					<div class="row mx-0 justify-content-center">
						<div class="d-flex justify-content-center mr-2 text-muted">
							<p class="font-12">Don't have an account?</p>
						</div>
						<div class="d-flex justify-content-center">
							<a
								class="signup-link"
								[routerLink]="'/signup'">
								Sign Up
							</a>
						</div>
					</div>
				</ng-container>
			</div>
			<div *ngIf="serverMessage && serverStatusCode == 200 && btnClicked" class="card-body py-3 mt-4 alert-success-purple rounded paddding-3-5 d-flex justify-content-center">
				{{ serverMessage }}
			</div>
			<div *ngIf="oError" class="card-body px-0 login-alert mt-2">
				<alert
					*ngIf="oError?.status"
					dismissible="true"
					class="alert-danger-red">
					{{ oError.error.message }}
				</alert>
			</div>
		</div>
	</div>
</div>

<div class="p-3 footer white-bg">
	<span>&copy;</span>
	<span>{{ _settingsService.getAppSetting('year') }}</span>
	<span class="mx-2">-</span>
	<span>{{ _settingsService.getAppSetting('name') }}</span>
</div>
