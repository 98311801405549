export const environment = {
	production: true,
	env: 'staging',
	apiEndPoint: 'https://qa.devapi.flocksy.com',
	baseURL: 'https://qa.devapp.flocksy.com',
	clunkBaseURL: 'https://qa.clunk.co',
	apiVersionURL: 'api/v1',
	ApiClientId: '2',
	ApiClientSecret: 'KGs5mSW43frUFrSv7NRDGGty8PUICdML5poa05Xl',
	s3BucketURL: 'https://devapp-flocksy.s3.amazonaws.com',
	landingSiteURL: 'https://flocksy.com',
	newThemeDeployedAt: '2019-08-17 16:24:20',
	analyticsEndPoint: 'https://analytics.devapi.flocksy.com',
	chatEndPoint: 'https://devchat.flocksy.com',
	socket: {
		connection: true,
	},
	reCaptchaSiteKey: '6LfT_BwqAAAAAAaIFBKRddbwI5Q2cRVA6_z-MFL-',
	stripePublishableKey: 'pk_test_QTEuTkAEEDv0CJMNkwUjKvnM',
	// stripePublishableKey: 'pk_test_5PtczUt5ICTK3P8BITJztfRC00f6z49jCW',
	useAWSS3: true,
	S3accessKeyId: 'AKIA24TLUKDWHNSPQD73',
	S3secretAccessKey: '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',
	S3region: 'us-east-1',
	S3Bucket: 'devapp-flocksy',
	externalSiteUrl: 'https://flocksy.com',
	feedbackeryIds: {
		dashboardClient: 'a9ExvLT7SUXNXWYE',
		dashboardDesigner: 'OLCYNRf1GugG2FF3',
	},
	analytics: {
		enabled: false,
		google: {
			id: 'UA-37049303-2',
			conversionId: 'AW-939790325/M3ByCPjYmpMBEPWfkMAD',
		},
	},
	development: false,
	thonestDataToken:
		'DKN93e6a4551b8eb5f1378cb4cc3f78f6d2b5717c85102c03f9b61e2a4c4986723d',
	clientPmAmThonestDataToken:
		'DKN9b1b8b6a4c133c50f36e7a82f2179a6023a20ca3fae06f91cd5683d7f823813c',
	loom: {
		enabled: true,
		publicAppId: 'd486ac5a-e055-420e-bbf4-6717368dd131',
	},
	oauthClients: {
		google: {
			clientId: '7',
			clientSecret: 'UFuhkRfDTDl8pk92r1SFtcJ1j0k7visLIPiaAMnC',
		},
		microsoft: {
			clientId: '8',
			clientSecret: '11psTqoqWdNwQTucwOn8BojrRPoo3DDvmvdadikc',
		},
	},
	freshChatConfig: {
		TOKEN: "878a017c-a40e-4c0b-a524-99def3b237a3",
		HOST: "https://wchat.freshchat.com"
	}
};
