import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../../../services/localstorage.service';
import { ToasterService } from 'angular2-toaster';
import { OauthService } from '../../../../services/oauth/oauth.service';
import { LoginService } from '../../../../services/login.service';
import { ApiService } from '../../../../services/common/api.service';
import { CommonService } from '../../../../services/common/common.service';
import { environment } from '../../../../../environments/environment';
import { CouponService } from '../../../../services/coupon/coupon.service';

@Component({
  selector: 'app-login-success-microsoft',
  templateUrl: './login-success-microsoft.component.html'
})
export class LoginSuccessMicrosoftComponent implements OnInit {

  code;
  oCoupon: object = {
		applied: false,
		statusCode: '',
		message: '',
		data: {},
	};
  coupon_code;
  isMonthFree = false;
  referralCode;

  constructor(public _activatedRoute: ActivatedRoute,
    public _localStorageService: LocalStorageService,
    public _router: Router,
    public _toasterService: ToasterService,
    public _loginService: LoginService,
    public _apiService: ApiService,
    public _commonService: CommonService,
    public _couponService: CouponService) { }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe((params) => {
      this.code = params['code'];
    });
    this.setCouponCodeViaQueryString();
  }

  executeAuthToken() {
    var oAuthData = {
			grant_type:'microsoft',
      client_id: environment.oauthClients.microsoft.clientId,
      client_secret: environment.oauthClients.microsoft.clientSecret,
      microsoft_auth_code:this.code,
      coupon_code: this._localStorageService.getCouponCode()
				? atob(this._localStorageService.getCouponCode())
				: '',
			coupon_applied_date:
				this._localStorageService.getCouponAppliedDate(),
      referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: this.getCookieValue('r')
				? this.getCookieValue('r')
				: null,
			utm_source: this._localStorageService.getUtmSource()
				? atob(this._localStorageService.getUtmSource())
				: this.getCookieValue('utm_source')
				? this.getCookieValue('utm_source')
				: null
    }

    this._loginService.oAuthToken(JSON.stringify(oAuthData)).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
			},
			(err) => {
				this._toasterService.pop('error', err.error.message);
        this._router.navigate(['/login']);
			}
		);
  }

  existsReferralCode() {
		if (!this.referralCode) {
			return false;
		}
		this._apiService.existsReferralCode(this.referralCode).subscribe(
			(data) => {
				if ('coupon_code' in data.data) {
					this._localStorageService.setCouponCode(
						btoa(data.data['coupon_code'])
					);
					this.onClickValidateCouponCode();
				}
			},
			(err) => {}
		);
	}

  setCouponCodeViaQueryString() {
    if (this.getCookieValue('r')) {
      this.referralCode = this.getCookieValue('r');
      this.existsReferralCode();
    } else {
      this.executeAuthToken();
    }
	}

  onClickValidateCouponCode() {
		let coupon_code = '';
		if (!!atob(this._localStorageService.getCouponCode())) {
			coupon_code = this._localStorageService.getCouponCode();
		}
		this.resetCouponData();
		if (coupon_code) {
			const oParams = {
				include: 'coupon',
			};
			this._couponService.exists(atob(coupon_code), oParams).subscribe(
				(data) => {
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = data.message;
					this._toasterService.pop('success', data.message);
					this.oCoupon['statusCode'] = data.status;
					this._localStorageService.setCouponCode(coupon_code);
					this._localStorageService.setCouponAppliedDate(new Date());
					this.oCoupon['data'] = data.data;

					this.coupon_code = atob(coupon_code);

					// Check if the coupon is for 1 Month free
					for (let i = 0; i < this.oCoupon['data'].length; i++) {
						const coupon = this.oCoupon['data'][i];
						if (
							coupon.type == 2 &&
							coupon.coupon &&
							coupon.coupon.percent_off == 100
						) {
							this.isMonthFree = true;
							break;
						}
					}

          this.executeAuthToken();
				},
				(err) => {
					this._localStorageService.setCouponCode('');
					this._localStorageService.setCouponAppliedDate('');
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = err.error.message;
					this.oCoupon['statusCode'] = err.error.status;
					this.oCoupon['data'] = [];
          this.executeAuthToken();
				}
			);
		}
	}

  resetCouponData() {
		this.oCoupon = {
			applied: true,
			statusCode: '',
			message: '',
			data: {},
		};
	}

  getCookieValue(name: string): string | null {
		const nameEQ = name + "=";
		const cookies = document.cookie.split(';');
	  
		for (let i = 0; i < cookies.length; i++) {
		  let cookie = cookies[i].trim();
		  if (cookie.indexOf(nameEQ) === 0) {
			return cookie.substring(nameEQ.length);
		  }
		}
		return null;
	}

  getAuthUser() {
		this._apiService.getAuthUser({ tracker_flagged: 1 }).subscribe(
			(data) => {
				this._localStorageService.setCurrentUser(data.data);
				this._toasterService.pop('success', 'Welcome back to Flocksy!');
				this._commonService.redirectUser();
			},
			(err) => {
        this._toasterService.pop('error', err.error.message);
        this._router.navigate(['/login']);
      }
		);
	}

}

