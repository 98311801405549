import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
import { CustomHttpService } from '../custom.http.service';

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	constructor(private _customHttpService: CustomHttpService) {}

	store(id, data): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.USERS_URL}/${id}/configs`,
			data
		);
	}

	index(id, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set('include[]', oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${id}/configs${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	destroy(id, data?): Observable<any> {
		let params = new URLSearchParams();
		for (const key in data) {
			params.set(key, data[key]);
		}

		return this._customHttpService.delete(
			`${API_CONSTANTS.USERS_URL}/${id}/configs${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	destroyTeamMember(id, configId, data?): Observable<any> {
		let params = new URLSearchParams();
		for (const key in data) {
			params.set(key, data[key]);
		}

		return this._customHttpService.delete(
			`${API_CONSTANTS.USERS_URL}/${id}/configs/${configId}${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	getPlatinumTeamMembers(id, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set('filter[' + key + ']', oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${id}/configs${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}
}
