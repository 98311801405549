import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { environment } from '@env/environment';
import { SettingsService } from '@core/settings/settings.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ApiService } from '@services/common/api.service';
import { CouponService } from '@services/coupon/coupon.service';
import { LocalStorageService } from '@services/localstorage.service';
import { LoginService } from '@services/login.service';
import { SignupService } from '@services/signup/signup.service';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { PLAN_COUPONS } from '@common/PlanCoupons';
import { ExternalScriptService } from '@services/common/external-script.service';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { API_CONSTANTS } from '@common/constant/ApiConstants';
import { CommonService } from '@services/common/common.service';

declare var grecaptcha: any;
declare var Rewardful;

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss', '../login/login.component.scss'],
})
export class SignupComponent implements OnInit {
	valForm: FormGroup;
	passwordForm: FormGroup;
	oError: object = {
		status: '',
	};
	serverStatusCode: number;
	serverMessage: string;
	oCoupon: object = {
		applied: false,
		statusCode: '',
		message: '',
		data: {},
	};
	isMonthFree = false;
	selectedCard = 1;
	coupon_code;
	card_index = 0;
	PLAN_COUPONS = PLAN_COUPONS;
	@ViewChild('widgetsContent', { read: ElementRef })
	public widgetsContent: ElementRef<any>;
	referralCode;

	cards_main = [
		{
			id: 1,
			title: 'Barb Pritchard',
			subtitle: 'Founder of Infinity Brand Designs',
			image: '../../../../assets/img/barb_pritchard.png',
			feedback:
				'“Flocksy has really transformed my business to a level that I never would have thought possible.”',
		},
	];

	cards = [
		{
			id: 1,
			title: 'Barb Pritchard',
			subtitle: 'Founder of Infinity Brand Designs',
			image: '../../../../assets/img/barb_pritchard.png',
			feedback:
				'“Flocksy has really transformed my business to a level that I never would have thought possible.”',
			detailedFeedback: `“I never thought I could have done what I've
			accomplished within one year with Flocksy. I
			totally need more than just one designer at a
			time, and the unlimited subscription plan was
			ideal. I like that I can build a team here and
			have dedicated designers I know, like, AND
			trust. It's really, really wonderful — I love my
			team of designers. I have faith in this team and
			no doubts they can handle any of my projects.”`,
		},

		{
			id: 2,
			title: 'Manuel Vega',
			subtitle: 'mvprivateholdings.com',
			image: '../../../../assets/img/Manuel_Vega_image.png',
			feedback:
				'“The quality of the work is amazingly superb and the turn around time is equally amazing.”',
			detailedFeedback: `“Flocksy is an excellent investment, especially if
			you're a small agency trying to compete with the big
			boys. It gives your company that marketing edge you
			need to compete and feel confident in being able to
			deliver quality products. The quality of the work is
			amazingly superb and the turn around time is equally
			amazing.” `,
		},
		{
			id: 3,
			title: 'Pamela Engstrom',
			subtitle: 'Lita P Designs',
			image: '../../../../assets/img/Pamela_Engstrom_image.png',
			feedback: '“writers that “get” me and my ideal customer”',
			detailedFeedback: `“I am a small business owner that is spread thinner
			than thin. There are not enough hours in the day for
			me to do all my own marketing, copywriting, graphics
			etc. And I am not at a point that I can afford to
			hire full time staff. My Flocksy team is a perfect
			partnership. I have found writers that “get” me and
			my ideal customer. They are fast, easy and all
			around amazing. Highly recommend.” `,
		},
	];
	signupPageTriggerId =
		FlocksyUtil.authenticationTriggers()['traditional-signup'];
	submitted = false;
	utmSource = '';

	constructor(
		public _localStorageService: LocalStorageService,
		public _apiService: ApiService,
		public _loginService: LoginService,
		public _signupService: SignupService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		public _settingsService: SettingsService,
		public _formBuilder: FormBuilder,
		public _toasterService: ToasterService,
		public _couponService: CouponService,
		public _analyticsService: AnalyticsService,
		public _externalScriptService: ExternalScriptService,
		public _commonService: CommonService
	) {}

	ngOnInit() {
		this.setCouponCodeViaQueryString();
		this.setReferralCodeViaQueryString();
		this.setUtmSourceViaQueryString();
	}

	isRewardfulLoaded() {
		return typeof Rewardful === 'function';
	}

	async init() {
		try {
			const response = await fetch(API_CONSTANTS.AUTH_SEAMLESS, {
				method: 'GET',
				headers: await this._commonService.getHeaders(),
			});
			if (response.ok) {
				if (this._localStorageService.isUserLoggedIn()) {
					this.checkUserLoggedIn();
				}
			} else {
				this._localStorageService.resetSessionStorage();
				this.reloadGoogleRecaptchaScript();
				this.initForm();
			}
		} catch (error) {
			this._localStorageService.resetSessionStorage();
			this.reloadGoogleRecaptchaScript();
			this.initForm();
		}
	}

	updateCardId(id) {
		this.selectedCard = id;
	}

	backButtonCardId() {
		if (this.selectedCard === 2) {
			if (this.cards_main[0].id !== 1) {
				this.cards_main.pop();
				this.cards_main.unshift(this.cards[0]);
				this.selectedCard = 1;
			} else {
				this.selectedCard = 1;
			}
		} else if (this.selectedCard === 1) {
			return null;
		} else if (this.selectedCard === 3) {
			if (this.cards_main[0].id !== 2) {
				this.cards_main.pop();
				this.cards_main.unshift(this.cards[1]);
				this.selectedCard = 2;
			}
		} else {
			this.selectedCard = 2;
		}
	}

	nextButtonCardId() {
		if (this.selectedCard === 1) {
			if (this.cards_main[0].id !== 2) {
				this.cards_main.shift();
				this.cards_main.push(this.cards[1]);
				this.selectedCard = 2;
			} else {
				this.selectedCard = 3;
			}
		} else if (this.selectedCard == 2) {
			if (this.cards_main[0].id !== 3) {
				this.cards_main.shift();
				this.cards_main.push(this.cards[2]);
				this.selectedCard = 3;
			}
		} else if (this.selectedCard === 3) {
			return null;
		} else {
			this.selectedCard = 2;
		}
	}

	nextButtonCardIdMobile() {
		if (this.card_index !== 2) {
			this.card_index++;
			this.selectedCard = this.card_index + 1;
		}
	}

	backButtonCardIdMobile() {
		if (this.selectedCard !== 0) {
			this.card_index--;
			this.selectedCard = this.card_index + 1;
		}
	}

	initForm() {
		const password = new FormControl(
			'',
			Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(16),
			])
		);
		const certainPassword = new FormControl('', [Validators.required]);

		this.passwordForm = this._formBuilder.group(
			{
				password: password,
				confirm_password: certainPassword,
			},
			{
				validator: FlocksyValidator.equalTo(
					'password',
					'confirm_password'
				),
			}
		);

		this.valForm = this._formBuilder.group({
			name: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.notUrl,
					FlocksyValidator.notEmail,
				]),
			],
			business_name: [null],
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			phone: [null],
			lead_source: [''],
			passwordGroup: this.passwordForm,
			agree_terms: [null, Validators.required],
			recaptcha_token: [null],
		});
	}

	setCouponCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('discount_code' in params) {
				if (params['discount_code']) {
					this._localStorageService.setCouponCode(
						btoa(params['discount_code'])
					);
					this.onClickValidateCouponCode();
				} else {
					this.init();
				}
			} else {
				this.init();
			}
		});
	}

	setReferralCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('r' in params) {
				this.referralCode = params['r'];
				this._localStorageService.setReferralCode(btoa(params['r']));
				this._localStorageService.setReferralAppliedDate(new Date());
				this.existsReferralCode();
			}
		});
	}

	setUtmSourceViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('utm_source' in params) {
				this.utmSource = params['utm_source'];
				this._localStorageService.setUtmSource(btoa(params['utm_source']));
			}
		});
	}

	checkUserLoggedIn() {
		if (this._localStorageService.isUserLoggedIn()) {
			this.redirectUser();
		}
	}

	submitForm($ev, value: any) {
		this.submitted = true;
		$ev.preventDefault();
		for (const c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		for (const c in this.passwordForm.controls) {
			this.passwordForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}
		this.generateTokenGoogleRecaptcha();
	}

	storeSubmit() {
		Object.assign(this.valForm.value, this.valForm.value.passwordGroup);

		const oPost = {
			coupon_code: this._localStorageService.getCouponCode()
				? atob(this._localStorageService.getCouponCode())
				: '',
			coupon_applied_date:
				this._localStorageService.getCouponAppliedDate(),
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		};
		if (
			this._localStorageService.getReferralAppliedDate() &&
			this._localStorageService.getReferralCode()
		) {
			if (
				this._localStorageService.getDateDiffHours(
					new Date(),
					new Date(this._localStorageService.getReferralAppliedDate())
				) <= 720
			) {
				oPost['referral_source'] = atob(
					this._localStorageService.getReferralCode()
				);
			}
		}
		if (this.isRewardfulLoaded()) {
			if (Rewardful.referral) {
				oPost['rewardful_referral'] = Rewardful.referral;
			}
		}

		Object.assign(this.valForm.value, oPost);

		this.valForm.value['user_type_id'] = 1;
		this.valForm.value['trigger_id'] = this.signupPageTriggerId;
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('utm_source' in params) {
				const utm_source = params['utm_source'];
				this.valForm.value['utm_source'] = utm_source;
			}
		});

		this._signupService.register(this.valForm.value).subscribe(
			(data) => {
				this.serverStatusCode = data.status;
				this.serverMessage = data.message;
				this._toasterService.pop('success', data.message);
				this.autoLoginAfterSignup(
					this.valForm.value.email,
					this.valForm.value.passwordGroup['password']
				);
				this.valForm.reset();
				this.submitted = false;
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	autoLoginAfterSignup(email, password) {
		const auth_data = {
			grant_type: 'password',
			username: email,
			password: password,
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			client_signup: 1,
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		};

		if (!!this.coupon_code) {
			this._localStorageService.setCouponCode(btoa(this.coupon_code));
		} else {
			this._localStorageService.removeItem('coupon_code');
			this._localStorageService.removeItem('coupon_applied_date');
		}
		this._loginService.oAuthToken(JSON.stringify(auth_data)).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.serverStatusCode = data.status;
				this.serverMessage = '';
				this.getAuthUser();
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	getAuthUser() {
		this._apiService.getAuthUser({ tracker_flagged: 1 }).subscribe(
			(data) => {
				this._localStorageService.setCurrentUser(data.data);
				this.redirectUser();
			},
			(err) => {}
		);
	}

	redirectUser() {
		if (this._localStorageService.isClientUser()) {
			if (this._localStorageService.getClientPlanID() != 0) {
				this._router.navigate(['/dashboard']);
			} else {
				if (this._localStorageService.getCouponCode()) {
					this._router.navigate(['/plans'], {
						relativeTo: this._activatedRoute,
						queryParams: {
							discount_code: atob(
								this._localStorageService.getCouponCode()
							),
						},
					});
				} else {
					this._router.navigate(['/plans']);
				}
				this._analyticsService.setRouteData(14, 25);
			}
		} else if (this._localStorageService.isDesignerUser()) {
			this._router.navigate(['/dashboard']);
		} else if (this._localStorageService.isProjectManagerUser()) {
			this._router.navigate(['/projects/active']);
		} else if (this._localStorageService.isAdminUser()) {
			this._router.navigate(['/projects/active']);
		} else {
			this._router.navigate(['/login']);
		}
	}

	existsReferralCode() {
		if (!this.referralCode) {
			return false;
		}
		this._apiService.existsReferralCode(this.referralCode).subscribe(
			(data) => {
				if ('coupon_code' in data.data) {
					this._localStorageService.setCouponCode(
						btoa(data.data['coupon_code'])
					);
					this.onClickValidateCouponCode();
				}
			},
			(err) => {}
		);
	}

	resetCouponData() {
		this.oCoupon = {
			applied: true,
			statusCode: '',
			message: '',
			data: {},
		};
	}

	onClickValidateCouponCode() {
		let coupon_code = '';
		if (!!atob(this._localStorageService.getCouponCode())) {
			coupon_code = this._localStorageService.getCouponCode();
		}
		this.resetCouponData();
		if (coupon_code) {
			const oParams = {
				include: 'coupon',
			};
			this._couponService.exists(atob(coupon_code), oParams).subscribe(
				(data) => {
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = data.message;
					this._toasterService.pop('success', data.message);
					this.oCoupon['statusCode'] = data.status;
					this._localStorageService.setCouponCode(coupon_code);
					this._localStorageService.setCouponAppliedDate(new Date());
					this.oCoupon['data'] = data.data;

					this.coupon_code = atob(coupon_code);

					// Check if the coupon is for 1 Month free
					for (let i = 0; i < this.oCoupon['data'].length; i++) {
						const coupon = this.oCoupon['data'][i];
						if (
							coupon.type == 2 &&
							coupon.coupon &&
							coupon.coupon.percent_off == 100
						) {
							this.isMonthFree = true;
							break;
						}
					}
					this.init();
				},
				(err) => {
					this.init();
					this._localStorageService.setCouponCode('');
					this._localStorageService.setCouponAppliedDate('');
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = err.error.message;
					this.oCoupon['statusCode'] = err.error.status;
					this.oCoupon['data'] = [];
				}
			);
		}
	}

	generateTokenGoogleRecaptcha() {
		if (this.coupon_code) {
			this._localStorageService.setCouponCode(btoa(this.coupon_code));
		}
		const thisObject = this;
		console.log(grecaptcha)
		grecaptcha.ready(function () {
			grecaptcha
				.execute(environment.reCaptchaSiteKey, {
					action: 'appSignupPage',
				})
				.then(function (token) {
					thisObject.assignRecaptchaToken(token);
					thisObject.storeSubmit();
				});
		});
	}

	reloadGoogleRecaptchaScript() {
		this._externalScriptService
			.loadScript(
				'https://www.google.com/recaptcha/api.js?render=' +
					environment.reCaptchaSiteKey,
				'flocksy_recaptcha'
			)
			.then((data) => {})
			.catch((error) => console.log(error));
	}

	assignRecaptchaToken(token) {
		this.valForm.patchValue({ recaptcha_token: token });
	}

	removeVisibilityGoogleRecaptcha() {
		const elements = document.getElementsByClassName('grecaptcha-badge');
		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	}

	destroyGoogleRecaptcha() {
		this.removeVisibilityGoogleRecaptcha();
		this._externalScriptService.removeScript('flocksy_recaptcha');
	}

	ngOnDestroy() {
		this.destroyGoogleRecaptcha();
	}
}
