<ng-template #paymentModel>
	<div class="modal-header">
		<p
			class="modal-title ml-1 f-18"
			*ngIf="showCardsList && !isViaSignUpPage">
			Select a card to finish the payment process
		</p>
		<p
			class="enter-payment-text ml-1 mt-2"
			*ngIf="!showCardsList || isViaSignUpPage">
			Enter a payment method to proceed
		</p>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="onClosePaymentModel()">
			<img src="assets/img/close-grey-light.svg" alt="" class="close-icon-dimension">
		</button>
	</div>
	<div class="modal-body p-0">
		<div class="container container-xl p-0" *ngIf="cards?.length">
			<app-cards-list
				[cards]="cards"
				[showCardsList]="showCardsList"
				[isCardsLoaded]="isCardsLoaded"
				[isViaSignUpPage]="isViaSignUpPage"
				(addNewCard)="
					initAddNewCardModal(addUserModal);
					_analyticsService.setOverlayTrackData(
						17,
						104,
						oProjectsData?.project?.project_id,
						2
					)
				"
				(selectedCard)="onSelectCard($event)"></app-cards-list>
		</div>
		<div
			class="container container-xl p-0"
			*ngIf="!showCardsList || isViaSignUpPage">
			<!-- add new card flow will be only available to user when they are updating existing billing info  -->
			<!-- <app-add-new-card
				[isViaSignUpPage]="isViaSignUpPage"
				[saveCardInfo]="saveCardInfo"
				(emitCloseModal)="onCloseModel($event)"
				(selectedStateId)="taxCalculate($event)"
				(isFormValidated)="
					isAddNewCardFormValidated($event)
				"></app-add-new-card> -->
		</div>
		<!-- <div class="container">
			<button
				*ngIf="showCardsList && isCardsLoaded"
				class="btn btn-labeled btn-primary mb-2 float-left pl-0 d-flex align-items-center"
				type="button"
				(click)="
					initAddNewCardModal();
					_analyticsService.setOverlayTrackData(
						17,
						104,
						oProjectsData?.project?.project_id,
						2
					)
				">
				<span
					class="btn-label new-card-payment-btn-wrapper save-project-btn-wrapper">
					<i class="fas fa-plus"></i>
				</span>
				<span class="pl-3">Add A New Card</span>
			</button>
		</div> -->
		<div class="container">
			<div class="px-2 pb-5 pt-0 d-flex justify-content-start w-100">
				<div
					[ngClass]="!selectedCard ? 'disable-payment-section' : ''"
					class="text-right">
					<!-- <div>Your total:</div> -->
					<!-- <div class="d-flex justify-content-end flex-column">
						<div
							class="d-flex align-items-baseline justify-content-end">
							<div
								*ngIf="
									selectedPackage?.discounted_total != null &&
									selectedPackage?.discounted_total !== 0
								"
								class="discounted-price-wrapper text-primary">
								${{
									+selectedPackage['discounted_total'] || 0
										| number: '1.0-2'
								}}
							</div>
							<div
								class="ml-2"
								[ngClass]="
									selectedPackage?.discounted_total != null &&
									selectedPackage?.discounted_total !== 0
										? 'package-original-price-wrapper'
										: ''
								">
								<span
									*ngIf="
										!planPaymentInfo?.isTaxIncluded &&
										selectedPackage?.discounted_total !=
											null &&
										selectedPackage?.discounted_total !== 0
									">
									${{
										+(selectedPackage
											? selectedPackage?.total
											: projectsData?.project_package
													?.total ||
											  totalPlanPayments)
											| number: '1.2-2'
									}}
								</span>
								<span
									*ngIf="
										!planPaymentInfo?.isTaxIncluded &&
										!selectedPackage?.discounted_total
									">
									${{
										+(selectedPackage
											? selectedPackage?.total
											: projectsData?.project_package
													?.total ||
											  totalPlanPayments) +
											+getStateTaxAmount()
											| number: '1.2-2'
									}}
								</span>
								<span *ngIf="!!planPaymentInfo?.isTaxIncluded">
									${{
										selectedPackage
											? selectedPackage?.total
											: (projectsData?.project_package
													?.total || totalPlanPayments
											  | number: '1.2-2')
									}}
								</span>
							</h1>
							<h1
								*ngIf="
									selectedPackage?.discounted_total != null &&
									selectedPackage?.discounted_total !== 0
								"
								class="discounted-price-wrapper text-primary">
								<span *ngIf="!planPaymentInfo?.isTaxIncluded">
									${{
										+(+selectedPackage[
											'discounted_total'
										]) + +getStateTaxAmount()
											| number: '1.2-2'
									}}
								</span>
								<span *ngIf="!!planPaymentInfo?.isTaxIncluded">
									${{
										+selectedPackage['discounted_total'] ||
											0 | number: '1.0-2'
									}}
								</span>
							</h1>
						</div>
						<div
							class="discount-code-wrapper d-flex justify-content-end my-1 mb-3"
							*ngIf="
								selectedPackage?.discounted_total != null &&
								selectedPackage?.discounted_total !== 0
							">
							<div class="discount-content-container">
								<img
									src="../../../../{{
										discountCouponBgImage
									}}"
									alt="" />
								<div
									class="discount-content-text-wrapper font-11">
									<span>
										{{
											selectedPackage?.discount
												| number: '1.0'
										}}% DISCOUNT
									</span>
								</div>
							</div>
						</div>
					</div> -->
					<div
						*ngIf="
							selectedCard?.state_id &&
							selectedCard?.state_id == stateTaxData?.stateId
						"
						class="mb-3">
						Your total includes {{ stateTaxData?.stateText }}:
						<strong>
							{{ stateTaxData?.stateTaxValue }}% - ${{
								getStateTaxAmount() | number: '1.0-2':'en-US'
							}}
						</strong>
					</div>
					<div class="text-left">
						<button
							[disabled]="isDisableBtn"
							class="btn payment-btn-wrapper ml-auto btn-height px-5"
							type="button"
							(click)="
								onPaymentProcess();
								_analyticsService.setOverlayTrackData(
									17,
									105,
									oProjectsData?.project?.project_id,
									2
								)
							">
							{{
								isViaSignUpPage
									? 'Complete Purchase'
									: 'Proceed with Payment'
							}}
						</button>
					</div>
					<div class="mt-4 text-left">
						<img
							class="mb-2 img-thumbnail thumb26 bg-transparent border-0"
							src="../../../../assets/img/new-shield-icon.svg"
							alt="Lock" />
						<img
							class="mb-2 thumb26 bg-transparent ml-2"
							src="{{ cardBrands['Visa']['img'] }}"
							alt="Visa" />
						<img
							class="mb-2 thumb26 bg-transparent ml-2"
							src="{{ cardBrands['MasterCard']['img'] }}" />
						<img
							class="mb-2 thumb26 bg-transparent ml-2"
							src="{{ cardBrands['American Express']['img'] }}" />
						<img
							class="mb-2 thumb26 bg-transparent ml-2"
							src="{{ cardBrands['JCB']['img'] }}" />
						<img
							class="mb-2 thumb26 bg-transparent ml-2"
							src="{{ cardBrands['Discover']['img'] }}" />
						<img
							class="mb-2 thumb26 bg-transparent ml-2"
							src="{{ cardBrands['UnionPay']['img'] }}" />
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #addUserModal>
	<div
		class="container container-xl p-0"
		*ngIf="!showCardsList || isViaSignUpPage">
		<app-add-new-card
			[isViaSignUpPage]="isViaSignUpPage"
			[saveCardInfo]="saveCardInfo"
			(emitCloseModal)="onCloseModel($event)"
			(cardError)="cardErrProcess()"
			(selectedStateId)="taxCalculate($event)"
			(isFormValidated)="
				isAddNewCardFormValidated($event)
			"
			(stripeErr)="stripeErr($event)"
			[btnClicked]="btnClicked"></app-add-new-card>
	</div>

	<div class="container">
		<div class="px-2 pt-0 pb-2 w-100">
			<div
				class="">
				<div
					*ngIf="
						selectedCard?.state_id &&
						selectedCard?.state_id == stateTaxData?.stateId
					"
					class="mb-4">
					Your total includes {{ stateTaxData?.stateText }}:
					<strong>
						{{ stateTaxData?.stateTaxValue }}% - ${{
							getStateTaxAmount() | number: '1.0-2':'en-US'
						}}
					</strong>
				</div>
				<div *ngIf="isViaSignUpPage">
					<button
						[disabled]="btnDisabled"
						class="btn payment-btn-wrapper ml-auto btn-height px-5"
						type="button"
						(click)="
							onPaymentProcess();
							_analyticsService.setOverlayTrackData(
								17,
								105,
								oProjectsData?.project?.project_id,
								2
							)
						">
						{{
							isViaSignUpPage
								? 'Complete Purchase'
								: 'Proceed with Payment'
						}}
					</button>
				</div>
				<div class="mt-4 mb-5" *ngIf="isViaSignUpPage">
					<img
						class="mb-2 thumb26 bg-transparent border-0"
						[src]="'../../../../' + shieldFillUncheck"
						alt="Lock" />
					<img
						class="mb-2 thumb26 bg-transparent ml-2"
						src="{{ cardBrands['Visa']['img'] }}"
						alt="Visa" />
					<img
						class="mb-2 thumb26 bg-transparent ml-2"
						src="{{ cardBrands['MasterCard']['img'] }}" />
					<img
						class="mb-2 thumb26 bg-transparent ml-2"
						src="{{ cardBrands['American Express']['img'] }}" />
					<img
						class="mb-2 thumb26 bg-transparent ml-2"
						src="{{ cardBrands['JCB']['img'] }}" />
					<img
						class="mb-2 thumb26 bg-transparent ml-2"
						src="{{ cardBrands['Discover']['img'] }}" />
					<img
						class="mb-2 thumb26 bg-transparent ml-2"
						src="{{ cardBrands['UnionPay']['img'] }}" />
				</div>
			</div>
		</div>
	</div>
</ng-template>
