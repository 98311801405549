import { NgModule } from '@angular/core';
import { FlocksyTimerComponent } from './flocksy-timer.component';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [CommonModule],
	exports: [FlocksyTimerComponent],
	declarations: [FlocksyTimerComponent],
	providers: [],
})
export class FlocksyTimerModule {}
