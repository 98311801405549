import { Injectable } from '@angular/core';
import { CustomHttpService } from './custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../common/constant/ApiConstants';

@Injectable()
export class LoginService {
	constructor(private _customHttpService: CustomHttpService) {}

	oAuthToken(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.OAUTH_TOKEN_URL,
			oData
		);
	}

	loginGuid(guid: string, oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.OAUTH_TOKEN_URL + '?guid=' + guid,
			oData
		);
	}

	loginWithPasswordCode(oData: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.OAUTH_PASSWORD_CODE_URL,
			oData
		);
	}
}
