import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
import { FlocksyUtil } from '../../common/FlocksyUtil';
import { TemplatePayload } from '../../models/template-payload.model';

@Injectable()
export class ProjectService {
	isSaveProject: BehaviorSubject<boolean> = new BehaviorSubject(false);
	constructor(private _customHttpService: CustomHttpService) {}

	getDefs(status = '', userId = '', oFilter?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		if (status) {
			params.set('status', status);
		}
		if (userId) {
			params.set('user_id', userId);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/defs' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	retrieveProjectTitle(bCopyBrief?, oFilter?, oSort?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		for (const key in oSort) {
			params.set(key, oSort[key]);
		}
		if (bCopyBrief) {
			params.set('copy_brief', '1');
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECT_TITLES_URL +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getDefsQuestion(
		type_id,
		title_id,
		user_id,
		project_id,
		oFilter?: object
	): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		if (user_id) {
			params.set('user_id', user_id);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/defs/questions?project_type_id=' +
				type_id +
				'&project_title_id=' +
				title_id +
				'&project_id=' +
				project_id +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	post(oData: any, oParams: any): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project/post' +
				(params.toString() ? '?' + params.toString() : ''),
			oData
		);
	}

	postTemplate(payload: TemplatePayload): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/projects/templates',
			payload
		);
	}

	updateTemplate(id: number, payload: TemplatePayload): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.API_ENDPOINT_URL + '/projects/templates/' + id,
			payload
		);
	}

	getTemplates(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/projects/templates'
		);
	}

	getTemplateById(id: number): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/projects/templates/' + id
		);
	}

	delete(id): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.API_ENDPOINT_URL + '/projects/' + id + '/archive',
			''
		);
	}

	update(id, oData: any): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.PROJECTS_URL + '/' + id,
			oData
		);
	}

	getPending(
		offset?,
		max?,
		project_type_id?: number,
		projectTitle?
	): Observable<any> {
		return this.getProjects(1, offset, 30, project_type_id, projectTitle);
	}

	sortPending(id, data) {
		return this._customHttpService.put(
			API_CONSTANTS.API_ENDPOINT_URL + '/projects/' + id + '/sort-orders',
			data
		);
	}

	getUnassigned(
		offset?,
		max?,
		project_type_id?: number,
		approve_work_preferences?,
		filter?: number
	): Observable<any> {
		project_type_id =
			typeof project_type_id !== 'undefined' ? project_type_id : 0;
		approve_work_preferences =
			typeof approve_work_preferences !== 'undefined'
				? approve_work_preferences
				: 0;
		filter = typeof filter !== 'undefined' ? filter : 0;
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/projects?project_status_id=' +
				4 +
				'&offset=' +
				offset +
				'&max_results=' +
				max +
				'&project_type_id=' +
				project_type_id +
				'&approve_work_preferences=' +
				approve_work_preferences +
				'&filter=' +
				filter
		);
		/*return this.getProjects(4, offset, max, project_type_id);*/
	}

	getAvailable(
		project_type_id?: number,
		stat?: string,
		approve_work_preferences?: number,
		oFilter?: object
	): Observable<any> {
		project_type_id =
			typeof project_type_id !== 'undefined' ? project_type_id : 0;
		stat = typeof stat !== 'undefined' ? stat : '';
		approve_work_preferences =
			typeof approve_work_preferences !== 'undefined'
				? approve_work_preferences
				: 0;
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/projects/available?project_type_id=' +
				project_type_id +
				'&stat=' +
				stat +
				'&approve_work_preferences=' +
				approve_work_preferences +
				'&track=1' +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	getActive(offset?, max?, project_type_id?: number): Observable<any> {
		return this.getProjects(2, offset, max, project_type_id);
	}

	getArchived(oParams, oFilters?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}

		for (let key in oFilters) {
			if (key === 'project_type_id') {
				params.set('filter[project_type_ids][]', oFilters[key]);
			} else {
				params.set('filter[' + key + ']', oFilters[key]);
			}
		}
	
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/archived?' + params.toString()
		);
	}

	getActiveForDesigner(
		offset?,
		max?,
		project_type_id?: number
	): Observable<any> {
		return this.getProjects(0, offset, max, project_type_id);
	}

	getCompleted(
		offset?,
		max?,
		project_type_id?: number,
		projectTitle?
	): Observable<any> {
		return this.getProjects(3, offset, max, project_type_id, projectTitle);
	}

	getDrafts(oParams, oFilters): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		for (let key in oFilters) {
			if (key === 'project_type_id') {
				params.set('filter[project_type_ids][]', oFilters[key]);
			} else {
				params.set('filter[' + key + ']', oFilters[key]);
			}
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/draft?' + params.toString()
		);
	}

	getCompletedForClient(oParams, oFilters): Observable<any> {
		let params = new URLSearchParams();

		// Add regular parameters
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}

		// Add filter parameters, including nested filters
		for (let key in oFilters) {
			if (key === 'project_type_id') {
				// Set filter[project_type_ids][] if the key is project_type_id
				params.set('filter[project_type_ids][]', oFilters[key]);
			} else if (typeof oFilters[key] === 'object' && oFilters[key] !== null) {
				for (let subKey in oFilters[key]) {
					params.set(
						`filter[${key}][${subKey}]`,
						oFilters[key][subKey]
					);
				}
			} else {
				params.set(`filter[${key}]`, oFilters[key]);
			}
		}

		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/completed?' + params.toString()
		);
	}

	getProjects(
		project_status_id?: number,
		offset?,
		max?,
		project_type_id?: number,
		projectTitle?
	): Observable<any> {
		project_type_id =
			typeof project_type_id !== 'undefined' ? project_type_id : 0;
		projectTitle = typeof projectTitle !== 'undefined' ? projectTitle : '';
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/projects?project_status_id=' +
				project_status_id +
				'&offset=' +
				offset +
				'&max_results=' +
				max +
				'&project_type_id=' +
				project_type_id +
				'&q=' +
				projectTitle
		);
	}

	getProjectsForDesigners(page: number, isInactive: boolean): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/projects/active' +
				'?page=' + page +
				'&filter[has_moved_inactive]=' + isInactive
		);
	}

	updateProjectStatus(projectId: number, payload: UpdateProjectStatusPayload): Observable<any> {
		const url = `${API_CONSTANTS.API_ENDPOINT_URL}/projects/${projectId}`;
		return this._customHttpService.put(url, payload);
	  }

	  getProjectsForClient(
		project_status_id?: number,
		offset?,
		max?,
		project_type_id?: number,
		projectTitle?
	): Observable<any> {
		
		project_type_id = typeof project_type_id !== 'undefined' ? project_type_id : 0;
		projectTitle = typeof projectTitle !== 'undefined' ? projectTitle : '';
	
		let url = API_CONSTANTS.API_ENDPOINT_URL +
			'/projects/pending?project_status_id=' +
			project_status_id +
			'&offset=' +
			offset +
			'&max_results=' +
			max;
	
		if (project_type_id && project_type_id !== 0) {
			url += '&filter[project_type_ids][]=' + project_type_id;
		}
	
		url += '&filter[search-query]=' + projectTitle;
	
		return this._customHttpService.get(url);
	}
	

	getPMStatusProjects(filter?: any): Observable<any> {
		typeof filter !== 'undefined' ? filter : '';
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/pm_status/projects?filter=' +
				filter
		);
	}

	getProjectTypes(oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				let i = 0;
				for (const value of oParams[key]) {
					params.set('filter[' + key + '][' + i + ']', value);
					i++;
				}
			} else {
				params.set(key, oParams[key]);
			}
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project-types' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getProjectsProjectType(oParams?) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				let i = 0;
				for (const value of oParams[key]) {
					params.set('filter[' + key + '][' + i + ']', value);
					i++;
				}
			} else {
				params.set('filter[' + key + ']', oParams[key]);
			}
		}

		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/project-types' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getProjectsProjectTitles(oParams?) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				let i = 0;
				for (const value of oParams[key]) {
					params.set('filter[' + key + '][' + i + ']', value);
					i++;
				}
			} else {
				params.set('filter[' + key + ']', oParams[key]);
			}
		}

		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/project-titles' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getClientsProjectTypes(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/team/project-types'
		);
	}

	getRecentActivity(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/project/activity/recent'
		);
	}

	getFiles(oParams, oFilters): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		for (let key in oFilters) {
			params.set('filter[' + key + ']', oFilters[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/files?' + params.toString()
		);
	}

	getProjectFiles(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/files'
		);
	}

	markAsLate(id): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/exclude-slots',
			{}
		);
	}

	getActiveForClient(page: number = 1, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (key === 'filter[project_type_id]') {
				params.set('filter[project_type_ids][]', oParams[key]);
			} else {
				params.set(key, oParams[key]);
			}
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/active?page=' +
				page +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	setStarSortOrder(id, order): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/star-sort-orders',
			{ star_sort_order: order }
		);
	}

	storeBulkStarSortOrder(order): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/bulk-star-sort-orders',
			order
		);
	}

	setReviewStatus(id, data): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/reviewed',
			data
		);
	}

	getAMInviteStats(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/am/invited-creatives/bid-seen/count'
		);
	}

	postUnarchiveProject(id, body: any): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/unarchived',
			body
		);
	}

	startFromDraft(id, oData: any, oParams: any): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.put(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/projects/' +
				id +
				(params.toString() ? '?' + params.toString() : ''),
			oData
		);
	}

	getClientProjects(
		project_type_id?: number,
		oFilter?: object
	): Observable<any> {
		project_type_id =
			typeof project_type_id !== 'undefined' ? project_type_id : 0;
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/projects/available?project_type_id=' +
				project_type_id +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	getUnassignedProjects(oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/admin/projects/unassigned?' +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	getStarredProjects() {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/projects/starred'
		);
	}

	getAvailableQueueProjects(oParams?) {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set('filter[' + key + ']', oParams[key]);
		}

		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				let i = 0;
				for (const value of oParams[key]) {
					params.set(key + '[' + i + ']', value);
					i++;
				}
			} else {
				params.set(key, oParams[key]);
			}
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				`/projects/available/queues?` +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	updateProjectIsPostedStatus(id, param) {
		return this._customHttpService.put(
			API_CONSTANTS.API_ENDPOINT_URL + `/projects/${id}`,
			param
		);
	}

	get(id, oParams?) {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				`/${id}?` +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	getAdminUnassignedProjects(
		offset?,
		max?,
		projectStatusId?,
		oParams?
	): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				let i = 0;
				for (const value of oParams[key]) {
					params.set(key + '[' + i + ']', value);
					i++;
				}
			} else {
				params.set(key, oParams[key]);
			}
		}
		const param = params.toString() ? '&' + params.toString() : '';
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/projects?project_status_id=' +
				projectStatusId +
				'&offset=' +
				offset +
				'&max_results=' +
				max +
				param
		);
		/*return this.getProjects(4, offset, max, project_type_id);*/
	}

	getProjectOffer(projectId) {
		return this._customHttpService.get(
			`${API_CONSTANTS.PROJECTS_URL}/${projectId}/offer`
		);
	}

	public formatProjectTypes(inputData) {
		let outputData = inputData.reduce((result, projectType) => {
			const group = result.find(
				(group) => group.concept_id === projectType.concept_id
			);
			if (group) {
				group.children.push(projectType);
			} else {
				result.push({
					concept_id: projectType.concept_id,
					text:
						projectType.concept_id ===
						FlocksyUtil.projectConcepts()['subscriptions']
							? 'Subscription Categories'
							: 'Marketplace Categories',
					children: [projectType],
				});
			}
			return result;
		}, []);

		outputData.sort((a, b) => b.text.localeCompare(a.text));
		outputData = [
			{
				project_type_id: 0,
				project_type_name: 'All Categories',
			},
			...outputData,
		];

		return outputData;
	}

	public formatProjectTitles(inputData) {
		let outputData = inputData;

		outputData = [
			{
				project_title_id: 0,
				project_title_name: 'All Subcategories',
			},
			...outputData,
		];

		return outputData;
	}

	public formatSubClients(inputData) {
		let outputData = inputData;

		outputData = [
			{
				id: 0,
				name: 'Select a Sub-Client',
			},
			...outputData,
		];

		return outputData;
	}

	public formatDesigners(inputData) {
		let outputData = inputData;

		outputData = [
			{
				id: 0,
				name: 'All Team Members',
			},
			...outputData,
		];

		return outputData;
	}

	getExcludedDesigners(projectId) {
		return this._customHttpService.get(
			`${API_CONSTANTS.PROJECTS_URL}/${projectId}/excluded/designers`
		);
	}

	getSubClientUser(userId) {
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${userId}/sub-clients`
		);
	}

	getPerformActions(projectId) {
		return this._customHttpService
			.get(`${API_CONSTANTS.PROJECTS_URL}/${projectId}/perform-actions`)
			.subscribe(
				(data) => {},
				(error) => {}
			);
	}

	getPendingProjects(offset?, max?, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				let i = 0;
				for (const value of oParams[key]) {
					params.set(key + '[' + i + ']', value);
					i++;
				}
			} else {
				params.set(key, oParams[key]);
			}
		}
		const param = params.toString() ? '&' + params.toString() : '';

		const projectParams = `${API_CONSTANTS.PROJECTS_URL}/pending?&offset=${offset}&max_results=${max}&${param}`;

		return this._customHttpService.get(projectParams);
	}

	postAIText(projectTypeId, data) {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECT_TYPES_URL +
				'/' +
				projectTypeId +
				'/openai/chat/completions',
			data
		);
	}

	postAIImgText(projectTypeId, data) {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECT_TYPES_URL +
				'/' +
				projectTypeId +
				'/openai/image/generations',
			data
		);
	}

	projectTooltipShown(projectsList, id) {
		let tooltipInfo = [];
		setTimeout(() => {
			projectsList.forEach((items, index) => {
				const element = document.getElementById(id + index);
				tooltipInfo[index] = element?.offsetWidth < element.scrollWidth;
			});
		}, 100);
		return tooltipInfo;
	}

	storeProjectMessageEmojiReactions(projectId, projectChatId, body): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.PROJECTS_URL}/${projectId}/chats/${projectChatId}/emoji-reactions`,
			body
		);
	}
	
	storeProjectMessage(projectId, body): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.PROJECTS_URL}/${projectId}/chats`,
			body);
	}

	deleteProjectMessageEmojiReaction(projectId, projectChatId, reactionId): Observable<any> {
		return this._customHttpService.delete(
			`${API_CONSTANTS.PROJECTS_URL}/${projectId}/chats/${projectChatId}/emoji-reactions/${reactionId}`
		);
	}
}

export interface UpdateProjectStatusPayload {
    title: string;
    preferences: {
        has_moved_inactive: boolean;
    };
}