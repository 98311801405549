<div class="ai-assist-wrapper">
	<div class="position-relative">
		<div
			[ngClass]="{
				'p-2':
					!aiConfig?.openai_text_generation ||
					!aiConfig?.openai_image_generation
			}">
			<p class="mb-1 text-color-gray">
				Let AI Assist You
				<em
					class="fa fa-info-circle clickable ml-1 f-18"
					[popover]="aiTooltip"
					triggers="mouseenter:mouseleave click"
					placement="auto"
					(click)="$event.stopPropagation()"></em>
				<ng-template #aiTooltip>
					This is a BETA feature. Please look over the content
					generated before submitting. We cannot guarantee the
					accuracy of its results.
				</ng-template>
			</p>
			<p class="disabled-text-wrapper mb-1 font-12">Optional</p>
			<div class="d-flex flex-wrap mb-4">
				<a
					href="javascript:void(0);"
					[ngClass]="{ disabled: !aiConfig?.openai_text_generation }"
					class="mr-4 disabled-text-wrapper clickable text-underline"
					(click)="openAITextModal(aiTextModal)">
					AI Text Generation
				</a>
				<a
					href="javascript:void(0);"
					[ngClass]="{ disabled: !aiConfig?.openai_image_generation }"
					class="disabled-text-wrapper clickable text-underline"
					(click)="openAIImgModal(aiImgModal)">
					AI Image Generation
				</a>
			</div>
		</div>
		<div
			class="position-absolute ai-overlay"
			*ngIf="
				!aiConfig?.openai_text_generation ||
				!aiConfig?.openai_image_generation
			">
			<a
				[routerLink]="['/subscriptions/plans']"
				class="text-purple-color clickable text-underline">
				Upgrade your plan to access AI assist
			</a>
		</div>
	</div>
	<div
		class="d-flex p-1 ai-assist-text-wrapper mb-4"
		*ngIf="
			(directionText && !!btnDisabled['textHistory']) ||
			(aiDirectionTextList.length && !!btnDisabled['textHistory'])
		">
		<div class="spinner-wrapper mx-2" *ngIf="isSpinnerShown">
			<img
				src="assets/img/create_project/hourglass-split.svg"
				alt="" />
			<div class="spinner-border" role="status">
				<span class="visually-hidden"></span>
			</div>
		</div>
		<div class="save-direction-icon mx-2" *ngIf="!isSpinnerShown">
			<app-true-icon [type]="'selected'"></app-true-icon>
		</div>
		<div class="d-flex justify-content-between w-100">
			<div class="text">
				AI Text Generation:
				<span class="disabled-text-wrapper">
					{{ directionText }}
				</span>
			</div>
			<div class="action-btn align-self-center">
				<span
					class="mx-2 text-underline clickable"
					(click)="openAITextModal(aiTextModal)">
					View
				</span>
				<span
					class="mx-2 text-underline disabled-text-wrapper clickable"
					(click)="deleteAiInfo(true)">
					Dismiss
				</span>
			</div>
		</div>
	</div>
	<div
		class="d-flex p-1 ai-assist-text-wrapper mb-4"
		*ngIf="
			(aiImgGeneratText && btnDisabled['imgHistory']) ||
			(aiImageList.length && btnDisabled['imgHistory'])
		">
		<div class="spinner-wrapper mx-2" *ngIf="bShowProgressBar">
			<img
				src="assets/img/create_project/hourglass-split.svg"
				alt="" />
			<div class="spinner-border" role="status">
				<span class="visually-hidden"></span>
			</div>
		</div>
		<div class="save-direction-icon mx-2" *ngIf="!bShowProgressBar">
			<app-true-icon [type]="'selected'"></app-true-icon>
		</div>
		<div class="d-flex justify-content-between w-100">
			<div class="text">
				AI Image Generation:
				<span class="disabled-text-wrapper">
					{{ aiImgGeneratText }}
				</span>
			</div>
			<div class="action-btn">
				<span
					class="mx-2 text-underline clickable"
					(click)="openAIImgModal(aiImgModal)">
					View
				</span>
				<span
					class="mx-2 text-underline disabled-text-wrapper clickable"
					(click)="deleteAiInfo()">
					Dismiss
				</span>
			</div>
		</div>
	</div>
</div>

<div class="direction-info-section mb-4">
	<div class="direction-title-wrapper">
		<p class="mb-1">
			Directions
			<span class="text-danger ng-star-inserted">*</span>
			<em
				class="fa fa-info-circle clickable ml-1 f-18"
				[popover]="directionsTooltip"
				triggers="mouseenter:mouseleave click"
				placement="auto"
				(click)="$event.stopPropagation()"></em>
			<ng-template #directionsTooltip>
				Click
				<button class="btn btn-sm direction-save-btn">Save</button>
				to save when a line of directions is complete. Press enter to
				begin another line.
			</ng-template>
		</p>
	</div>
	<div cdkDropList (cdkDropListDropped)="drop($event)">
		<ng-container *ngFor="let data of directionList; let i = index">
			<div class="direction-wrapper d-flex mb-2" cdkDrag>
				<div class="d-flex icon-wrapper justify-content-center align-items-center">
					<ng-container *ngIf="!data?.has_from_openai">
						<div [popover]="'Written by the client'"
							 container="body"
							 triggers="mouseenter:mouseleave"
							 placement="right"
							 class="w-100 clickable ai-label d-flex flex-column justify-content-center align-items-center"
						>
							<img *ngIf="!data?.has_from_openai" width="16" [src]="'../../../../../' + bulbIcon" alt="" />
						</div>
					</ng-container>
					<ng-container *ngIf="data?.has_from_openai && !data?.has_openai_edited">
						<div [popover]="'Generated by AI'"
							 container="body"
							 triggers="mouseenter:mouseleave"
							 placement="right"
							class="w-100 clickable ai-label d-flex flex-column rounded justify-content-center align-items-center">
							<img alt="AI Generated" src="assets/img/create_project/ai.svg">
						</div>
					</ng-container>
					<ng-container *ngIf="data?.has_from_openai && data?.has_openai_edited">
						<div [popover]="'Generated by AI and edited by the client'"
							 container="body"
							 triggers="mouseenter:mouseleave"
							 placement="right"
							class="w-100 clickable ai-label d-flex flex-column rounded justify-content-center align-items-center text-white">
							<img alt="AI Edited" src="assets/img/create_project/ai-edited.svg">
						</div>
					</ng-container>
				</div>

				<div
					class="direction-text-wrapper d-flex w-100 position-relative"
					*ngIf="!data?.isUpdate">
					<ng-container *ngIf="divHeights[i]">
						<!-- Check if height is more than 450 -->
						<div
							id="ai-{{ i }}"
							class="w-100 b direction-text overflow-text-wrapper"
							[innerHTML]="data?.textHtml"></div>
						<ng-template #directionInfoTooltip>
							<div [innerHTML]="data?.text"></div>
						</ng-template>
					</ng-container>
					<ng-container *ngIf="!divHeights[i]">
						<div
							id="ai-{{ i }}"
							class="b direction-text w-100"
							[innerHTML]="data?.textHtml"></div>
					</ng-container>
					<div class="edit-icon-wrapper mx-2">
						<div
							class="clickable"
							[popover]="editTooltip"
							triggers="mouseenter:mouseleave click"
							container="body"
							(click)="toggleEditMode(i)">
							<app-edit-icon></app-edit-icon>
						</div>
						<ng-template #editTooltip>Edit</ng-template>
					</div>
					<div
						class="delete-icon-wrapper mr-2 clickable"
						[popover]="deleteTooltip"
						triggers="mouseenter:mouseleave click"
						container="body"
						(click)="deleteDirection(i)">
						<app-delete-icon></app-delete-icon>
						<ng-template #deleteTooltip>Delete</ng-template>
					</div>
					<div class="input-group-append">
						<div
							class="d-flex ml-auto input-group-text move-icon-group p-0 clickable">
							<div class="move-icon-wrapper h-100" cdkDragHandle>
								<img
									[src]="MoveIcon"
									alt="" />
							</div>
						</div>
					</div>
				</div>
				<div class="input-group" *ngIf="data?.isUpdate">
					<textarea
						class="form-control"
						#resizeTextarea
						(input)="adjustTextarea($event, i)"
						(focus)="adjustTextarea($event, i)"
						(keydown)="onKeyDown($event, resizeTextarea, i)"
						[(ngModel)]="data.updateDirection"
						[disabled]="!data?.isUpdate"
						maxlength="5000"
						placeholder="Start typing general directions"
						rows="2"></textarea>
					<div class="save-icon-wrapper d-none d-md-flex save-direction-btn btn mx-2">
						<div
							class="clickable"
							*ngIf="data?.isUpdate"
							(click)="updateDirectionInfo(i, resizeTextarea)">
							Update
						</div>
					</div>
					<div *ngIf="data?.isUpdate"
						 (click)="updateDirectionInfo(i, resizeTextarea)" class="save-icon-wrapper mr-2 clickable d-md-none">
						<img width="18" height="21" src="assets/img/checked.svg" alt="Update" title="Update">
					</div>
					<div
						class="delete-icon-wrapper mr-2 clickable"
						[popover]="deleteDirectionTooltip"
						triggers="mouseenter:mouseleave click"
						container="body"
						(click)="deleteDirection(i)">
						<app-delete-icon></app-delete-icon>
					</div>
					<ng-template #deleteDirectionTooltip>Delete</ng-template>
					<div class="input-group-append h-100">
						<div
							class="d-flex ml-auto input-group-text move-icon-group p-0 clickable">
							<div class="move-icon-wrapper" cdkDragHandle>
								<img
									[src]="MoveIcon"
									alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<p class="d-flex justify-content-end disabled-text-wrapper mb-3">
				<span>
					<span
						*ngIf="
							data?.updateDirection?.length == 5000 &&
							data?.isUpdate
						">
						5000 character limit exceed.
					</span>
					<span *ngIf="data?.isUpdate">
						{{ data?.updateDirection?.length || 0 }}/5000
					</span>
					<span *ngIf="!data?.isUpdate">
						{{ data?.text?.length || 0 }}/5000
					</span>
				</span>
			</p>
		</ng-container>
	</div>
	<div class="input-wrapper d-flex align-items-center mb-2">
		<div class="input-group ml-0">
			<textarea
				#textarea
				class="form-control custom-direction-textarea custom--wrapper pr-5"
				[ngClass]="{
					'border-red':
						(isValidationShown &&
							!isMarketplaceTab &&
							!directionList?.length) ||
						(isMarketplaceValidationShown &&
							isMarketplaceTab &&
							!directionList?.length)
				}"
				[(ngModel)]="customDirective"
				(input)="adjustTextarea($event)"
				maxlength="5000"
				placeholder="Start typing general directions"
				(keydown)="onKeyDown($event, textarea)"
				rows="2"></textarea>
			<div class="input-group-append">
				<div class="input-group-text p-0">
					<button
						[disabled]="!customDirective || customDirective?.trim().length === 0"
						[ngStyle]="{'cursor':!customDirective || customDirective?.trim().length === 0 ? 'not-allowed':'pointer'}"
						class="save-direction-icon save-direction-btn btn"
						(click)="saveDirectionInfo(textarea)">
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
	<p class="d-flex justify-content-between disabled-text-wrapper">
		<span>
			Click
			<button class="btn btn-sm direction-save-btn">Save</button>
			to save directions or create more.
		</span>
		<span>
			<span *ngIf="customDirective?.length == 5000">
				5000 character limit exceed.
			</span>
			{{ customDirective?.length || 0 }}/5000
		</span>
	</p>
	<div
		class="text-danger"
		*ngIf="
			(isValidationShown &&
				!directionList?.length &&
				!isMarketplaceTab) ||
			(isMarketplaceValidationShown &&
				!directionList?.length &&
				isMarketplaceTab)
		">
		This field is required.
	</div>
</div>

<ng-template #aiTextModal>
	<div class="modal-header">
		<p class="modal-title f-24">AI Text Generation</p>
		<img
			class="close clickable m-0 p-2 mt-1"
			[popover]="aiTextCloseTooltip"
			triggers="mouseenter:mouseleave click"
			container="body"
			[src]="closeIcon"
			alt="close"
			(click)="hideModalDetail(true)" />
		<ng-template #aiTextCloseTooltip>Close</ng-template>
	</div>
	<div class="modal-body p-4">
		<div class="ai-text-generator">
			<div class="d-flex mb-4">
				<input
					type="text"
					[(ngModel)]="directionText"
					class="form-control mr-3"
					placeholder="What are you looking for?" />
				<button
					class="btn generate-btn ml-3"
					type="submit"
					[disabled]="
						!directionText ||
						btnDisabled['aiTextGenerationBtn'] ||
						!selectedPackage['title']
					"
					(click)="postAIText()">
					Generate
				</button>
			</div>
			<div class="my-4 direction-spinner-wrapper" *ngIf="textProgressBar">
				<div class="spinner-border" role="status">
					<span class="visually-hidden"></span>
				</div>
			</div>
			<div class="text-center mb-4" *ngIf="regenerateMessageShown">
				<p>Do you want regenerate AI text?</p>
				<div class="d-flex justify-content-center">
					<button
						class="btn done-btn mr-2"
						(click)="regenerateAiText()">
						Yes
					</button>
					<button
						class="btn no_button"
						(click)="regenerateMessageShown = false;">
						No
					</button>
				</div>
			</div>
			<div
				class="generate-direction-list d-flex mb-4"
				*ngFor="let item of aiDirectionTextList; let i = index">
				<img [src]="grayBulbIcon" alt="" />
				<div class="d-flex w-100 justify-content-between ml-3 bb py-1">
					<div
						class="ai-direction-text w-100"
						[ngClass]="{
							'red-border-wrapper':
								editAiBtnEnable &&
								validationMessageShown &&
								item?.isEdit
						}">
						<textarea
							*ngIf="item?.isEdit"
							class="form-control custom-direction-textarea mr-3"
							[(ngModel)]="item['updatedText']"
							(input)="adjustTextarea($event)"
							maxlength="5000"
							placeholder="Start typing general directions"
							(keydown)="onKeyDown($event, textarea)"
							rows="2"></textarea>
						<p *ngIf="!item?.isEdit" class="my-1">
							{{ item?.text }}
						</p>
					</div>
					<div class="action-icon-wrapper d-flex align-items-center">
						<div class="edit-icon mx-3 mt-1">
							<div
								class="clickable"
								*ngIf="!item?.isEdit"
								[popover]="editAITooltip"
								triggers="mouseenter:mouseleave click"
								container="body"
								(click)="editAiDirectionText(i)">
								<app-edit-icon></app-edit-icon>
								<ng-template #editAITooltip>Edit</ng-template>
							</div>
							<div
								class="clickable"
								*ngIf="item?.isEdit"
								(click)="
									item?.updatedText
										? saveUpdatedDirectionText(i)
										: ''
								">
								<div
									class="save-direction-icon save-direction-btn btn clickable">
									Save
								</div>
							</div>
						</div>
						<div class="true-icon mx-3">
							<div
								class="checkbox c-checkbox mr-0 mt-1"
								[popover]="selectAITooltip"
								triggers="mouseenter:mouseleave click"
								container="body">
								<label class="mb-0">
									<input
										type="checkbox"
										[(ngModel)]="item['isSelected']"
										(input)="
											handleCheckboxChange($event, i)
										" />
									<span
										class="fa fa-check"
										[ngClass]="{
											'red-border-wrapper':
												!editAiBtnEnable &&
												validationMessageShown &&
												!addAiBtnEnable
										}"></span>
								</label>
							</div>
							<ng-template #selectAITooltip>Select</ng-template>
						</div>
						<div
							class="delete-icon ml-3 clickable"
							[popover]="deleteAITooltip"
							triggers="mouseenter:mouseleave click"
							container="body"
							(click)="deleteAiDirection(i)">
							<app-delete-icon></app-delete-icon>
							<ng-template #deleteAITooltip>Delete</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="aiDirectionTextList?.length">
			<hr />
			<div class="text-right">
				<button class="btn done-btn" (click)="addAiDirection()">
					Add
				</button>
			</div>
		</ng-container>
	</div>
</ng-template>

<ng-template #aiImgModal>
	<div class="modal-header">
		<p class="modal-title f-24">AI Image Generation</p>
		<img
			class="close clickable m-0 p-2 mt-1"
			[src]="closeIcon"
			alt="close"
			(click)="hideModalDetail()" />
	</div>
	<div class="modal-body p-4">
		<div class="ai-img-generator">
			<div class="d-flex mb-4">
				<input
					type="text"
					[(ngModel)]="aiImgGeneratText"
					class="form-control mr-3"
					placeholder="What are you looking for?" />
				<button
					class="btn generate-btn ml-3"
					type="submit"
					[disabled]="
						!aiImgGeneratText ||
						btnDisabled['aiImgGenerationBtn'] ||
						!selectedPackage['title']
					"
					(click)="imgGeneration()">
					Generate
				</button>
			</div>
			<div
				class="my-4 progress-br-wrapper direction-spinner-wrapper"
				*ngIf="bShowProgressBar">
				<div class="spinner-border" role="status">
					<span class="visually-hidden"></span>
				</div>
			</div>
			<div class="ai-img-section">
				<div class="d-flex flex-wrap">
					<div *ngFor="let item of aiImageList; let i = index">
						<img
							[src]="item.fullPath"
							alt=""
							class="mr-4"
							width="248px"
							height="248px" />
						<div class="action-tab p-1">
							<img
								(click)="openAIImgSliderModal(aiImgSlider)"
								class="mx-2 clickable"
								[src]="searchIcon"
								alt="search" />
							<img
								*ngIf="!item?.isSelected"
								(click)="onSelectFile(i)"
								class="mx-2 clickable"
								[src]="whitePlusIcon"
								alt="add" />
							<div
								*ngIf="item?.isSelected"
								class="d-inline-block mx-2 clickable"
								(click)="unSelectImg(i)">
								<app-true-icon
									[type]="'selected'"></app-true-icon>
							</div>
							<img
								(click)="onDeleteAiImg(i)"
								class="mx-2 clickable"
								[src]="whiteDeleteIcon"
								alt="delete" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="aiImageList?.length">
			<hr />
			<div class="text-right">
				<button class="btn done-btn" (click)="hideModalDetail()">
					Done
				</button>
			</div>
		</ng-container>
	</div>
</ng-template>

<ng-template #aiImgSlider>
	<div class="modal-header">
		<p class="modal-title f-24">Ai Image Generation - Results</p>
		<img
			class="close clickable m-0 p-2 mt-1"
			[src]="closeIcon"
			alt="close"
			(click)="
				aiImgSliderModalRef.hide(); btnDisabled['imgHistory'] = true
			" />
	</div>
	<div class="modal-body p-4 ai-img">
		<div class="mb-4">
			<p class="my-2">
				Show an image of a figure or a team facing a challenge, perhaps
				navigating through rugged terrain or weathering a storm.
			</p>
		</div>
		<carousel
			[interval]="0"
			[itemsPerSlide]="1"
			[showIndicators]="false"
			[noWrap]="true">
			<slide class="m-0" *ngFor="let item of aiImageList; let i = index">
				<img
					[src]="item?.fullPath"
					alt=""
					width="855px"
					height="855px" />
				<div class="action-tab slide-img p-1">
					<img
						(click)="openAIImgModal(aiImgModal)"
						class="mx-2 clickable"
						[src]="searchIcon"
						alt="search" />
					<img
						*ngIf="!item?.isSelected"
						(click)="onSelectFile(i)"
						class="mx-2 clickable"
						[src]="whitePlusIcon"
						alt="add" />
					<div
						*ngIf="item?.isSelected"
						class="d-inline-block mx-2 clickable"
						(click)="unSelectImg(i)">
						<app-true-icon [type]="'selected'"></app-true-icon>
					</div>
					<img
						(click)="onDeleteAiImg(i)"
						class="mx-2 clickable"
						[src]="whiteDeleteIcon"
						alt="delete" />
				</div>
			</slide>
		</carousel>
		<ng-container *ngIf="aiImageList?.length">
			<hr />
			<div class="text-right">
				<button
					class="btn done-btn"
					(click)="
						aiImgSliderModalRef.hide();
						btnDisabled['imgHistory'] = true
					">
					Done
				</button>
			</div>
		</ng-container>
	</div>
</ng-template>
