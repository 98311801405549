import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { LocalStorageService } from '@services/localstorage.service';
import { ApiService } from '@services/common/api.service';
import { CommonService } from '@services/common/common.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { OnboardingDesignService } from '@services/onboarding/onboarding-design.service';
import 'rxjs/add/operator/first';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	user: object = {};

	constructor(
		private _localStorageService: LocalStorageService,
		private _router: Router,
		private _analyticsService: AnalyticsService,
		private _onboardingDesignService: OnboardingDesignService,
		public _commonService: CommonService,
		private _apiService: ApiService
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.init(next, state);
	}

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.init(next, state);
	}

	init(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		var oSubject = new Subject<boolean>();
		if (this._localStorageService.isOperationalClientUser()) {
			this._apiService.getAuthSeamless().subscribe(
				(data) => {
					if ('client' in data.data) {
						let isPlanCancelled =
							data.data['client']['is_plan_cancelled'];
						let isPlanFailed =
							data.data['client']['is_payment_failed'];
						let isPlanActivated =
							data.data['client']['plan_activated'];
						if (data.data['client']['has_marketplace_access']) {
							this._localStorageService.setMarketPlaceAccess(
								true
							);
							this._localStorageService.setItem(
								'auth_seamless',
								data.data['client']
							);
							if (data.data['client']['is_payment_failed']) {
								if (state.url != '/plans/billing-info') {
									this._router.navigate([
										'/plans/billing-info',
									]);
									oSubject.next(false);
								}
							}
							if (data.data['client']['is_plan_cancelled']) {
								if (state.url.includes('package_id')) {
									oSubject.next(true);
								} else if (state.url != '/plans') {
									this._router.navigate(['/plans']);
									oSubject.next(false);
								}
							}
							if (
								!isPlanActivated &&
								!isPlanCancelled &&
								!isPlanFailed
							) {
								if (state.url.includes('package_id')) {
									oSubject.next(true);
								} else if (state.url != '/plans') {
									oSubject.next(true);
								}
							}
							oSubject.next(true);
						} else if ('plan_activated' in data.data['client']) {
							if (
								data.data['client']['is_updated_billing'] ==
									0 ||
								data.data['client']['is_payment_failed']
							) {
								if (state.url != '/plans/billing-info') {
									this._router.navigate([
										'/plans/billing-info',
									]);
									oSubject.next(false);
								}
							} else {
								if (
									!data.data['client']['plan_activated'] &&
									!data.data['client'][
										'has_marketplace_access'
									]
								) {
									this._router.navigate(['/plans']);
									this._analyticsService.setRouteData(14, 27);
									oSubject.next(false);
								} else {
									oSubject.next(true);
								}
							}
						} else if (
							'is_payment_failed' in data.data['client'] &&
							data.data['client']['is_payment_failed']
						) {
							if (state.url != '/plans/billing-info') {
								this._router.navigate(['/plans/billing-info']);
								oSubject.next(false);
							}
						} else if (data.data['client']['is_plan_cancelled']) {
							if (state.url.includes('package_id')) {
								oSubject.next(true);
							} else if (state.url != '/plans') {
								this._router.navigate(['/plans']);
								oSubject.next(false);
							}
						} else {
							oSubject.next(false);
						}
					} else if ('subclient' in data.data) {
						if (
							'plan_activated' in data.data['subclient']['client']
						) {
							if (
								data.data['subclient']['client'][
									'plan_activated'
								]
							) {
								if (
									data.data['subclient']['client'][
										'is_updated_billing'
									] == 0 ||
									data.data['subclient']['client'][
										'is_payment_failed'
									]
								) {
									if (state.url != '/billing-info') {
										this._router.navigate([
											'/billing-info',
										]);
										oSubject.next(false);
									} else {
										oSubject.next(true);
									}
								} else if (
									data.data['subclient']['client'][
										'is_plan_cancelled'
									]
								) {
									if (state.url.includes('package_id')) {
										oSubject.next(true);
									} else if (state.url != '/plans') {
										this._router.navigate(['/plans']);
										oSubject.next(false);
									}
								}
								oSubject.next(true);
							} else if (
								data.data['subclient']['client'][
									'is_plan_cancelled'
								]
							) {
								if (state.url.includes('package_id')) {
									oSubject.next(true);
								} else if (state.url != '/plans') {
									this._router.navigate(['/plans']);
									oSubject.next(false);
								}
							} else {
								this._router.navigate(['/plans']);
								this._analyticsService.setRouteData(14, 27);
								oSubject.next(false);
							}
						} else if (
							'is_payment_failed' in
								data.data['subclient']['client'] &&
							data.data['subclient']['client'][
								'is_payment_failed'
							]
						) {
							if (state.url != '/plans/billing-info') {
								this._router.navigate(['/plans/billing-info']);
								oSubject.next(false);
							}
						} else if (data.data['client']['is_plan_cancelled']) {
							if (state.url.includes('package_id')) {
								oSubject.next(true);
							} else if (state.url != '/plans') {
								this._router.navigate(['/plans']);
								oSubject.next(false);
							}
						} else {
							oSubject.next(false);
						}
					} else {
						oSubject.next(false);
					}
				},
				(err) => {
					this._router.navigate(['/login']);
					oSubject.next(false);
				}
			);
			return oSubject.asObservable().first();
		} else if (this._localStorageService.isDesignerUser()) {
			this._apiService.getAuthSeamless().subscribe(
				(data) => {
					if ('designer' in data.data) {
						if (!data.data['designer']['updated_location_info']) {
							this._commonService.actionTriggered.emit({
								form: 'updated_location_info',
							});
							oSubject.next(true);
						} else {
							if (
								data.data['designer'][
									'onboarding_active_step'
								] &&
								data.data['designer'][
									'onboarding_active_step'
								] > 5
							) {
								oSubject.next(true);
							} else {
								const prevPage =
									this._localStorageService.getItem(
										'prevPageId'
									);
								if (prevPage == '1') {
									this._analyticsService.setRouteData(13, 22);
								} else {
									this._analyticsService.setRouteData(13, 21);
								}
								this._onboardingDesignService.onboardingRedirect(
									data.data['designer'][
										'onboarding_active_step'
									]
								);
								oSubject.next(true);
							}
						}
					} else {
						oSubject.next(false);
					}
				},
				(err) => {
					this._router.navigate(['/login']);
					oSubject.next(false);
				}
			);
			return oSubject.asObservable().first();
		} else {
			this._apiService.getAuthSeamless().subscribe(
				(data) => {
					oSubject.next(true);
				},
				(err) => {
					this._router.navigate(['/login']);
					oSubject.next(false);
				}
			);
			return oSubject.asObservable().first();
		}
	}
}
