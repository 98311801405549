import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '@common/constant/ApiConstants';

@Injectable()
export class UserInviteService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, searchKeyword?: number | string): Observable<any> {
		let url = `${API_CONSTANTS.USERS_URL}/${id}/invites`;
		let params = [];

		if (searchKeyword) {
			params.push(`filter[q]=${searchKeyword}`);
		}
	
		if (params.length > 0) {
			url += `?${params.join('&')}`;
		}
		
		return this._customHttpService.get(url);
	}

	destroy(id, invite_id): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.USERS_URL + '/' + id + '/invites/' + invite_id
		);
	}
}
