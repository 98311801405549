<div class="bucket-info-wrapper">
	<div>
		<div class="my-2 d-flex flex-row" *ngIf="!isSkeletonLoading">
			<div>
				<span>
					Select a brand bucket
					<em
						class="fa fa-info-circle clickable ml-1 f-18"
						[popover]="bucketTooltip"
						placement="auto"
						triggers="mouseenter:mouseleave click"></em>
					<ng-template #bucketTooltip>
						A Brand Bucket contains all the relevant files for a brand,
						including your style guide, color palette, etc. Choose the
						Bucket to attach to this project. If you need to make one,
						select "Add New Bucket" on the right.
					</ng-template>
				</span>
			</div>
			<div class="mb-4 d-flex align-items-center">
				<span class="mx-4 disabled-text-wrapper">Or</span>
				<a
					class="text-dark clickable text-underline"
					type="button"
					(click)="initAddBucketModal(addBucketModal)"
					*ngIf="originalBuckets?.length <= enabledBuckets - 1 || (!isClientUser && !isSubClientUser)">
					Add a brand bucket
				</a>
				<a
					class="text-muted clickable text-underline"
					type="button"
					[popover]="upgradePlanText"
					triggers="mouseenter:click click"
					[outsideClick]="true"
					container="body"
					href="javascript:"
					*ngIf="originalBuckets?.length > enabledBuckets - 1 && (isClientUser || isSubClientUser)">
					Add a brand bucket
				</a>
				<ng-template #upgradePlanText>
					<div class="bucket-tooltip-text">
						Bucket limit reached. Your account limit is {{enabledBuckets}} buckets and the limit has
						been reached. Please <a [routerLink]="'/subscriptions/plans'"><span class="upgrade-plan-text">upgrade your plan</span></a> to add more buckets.
					</div>
				</ng-template>
			</div>
		</div>

		<div
			class="sub-category-info-wrapper mb-4"
			>
			
			<div class="mb-4" *ngIf="dataLoaded">
				<input
					#myInput
					type="text"
					(input)="filterData()"
					[(ngModel)]="searchableText"
					class="form-control search-category"
					placeholder="Start typing and select a bucket" />
			</div>

			<div *ngIf="!searchInProgress && notBucketFound && !isSkeletonLoading" class="text-center">
				No results found
			</div>

			<div *ngIf="isSkeletonLoading">
				<carousel
					[interval]="'false'"
					[itemsPerSlide]="itemsPerSlide"
					[showIndicators]="false"
					[noWrap]="true"
					carouselActiveSlide>
					<slide
						class="m-0"
						*ngFor="
							let bucket of [].constructor(20);
							let i = index; trackBy: trackByFn
						">
						<div
							class="slide card mx-3 team-member-slider mb-0 clickable relative-container"
							>
							<div class="card-body p-3 card-padding">
								<div class="row mx-0 justify-content-between">
									<div class="row mx-0">
										<div>
											<app-skeleton-loader
												[height]="'22px'"
												[width]="'22px'"
												></app-skeleton-loader>
										</div>
										<div class="pl-3">
											<app-skeleton-loader
												[height]="'27px'"
												[width]="'38px'"
												></app-skeleton-loader>
										</div>
									</div>
									<div>
										<div class="btn-group" dropdown container="body">
											<app-skeleton-loader
												[height]="'16px'"
												[width]="'16px'"
												></app-skeleton-loader>
										</div>
									</div>
								</div>
								<div>
									<hr class="margin-bottom-1-point-2-rem">
								</div>
								<div>
									<div class="row mx-0">
										<div>
											<app-skeleton-loader
												[height]="'35px'"
												[width]="'35px'"
												></app-skeleton-loader>
										</div>
										<div class="data-custom-width padding-left-2-rem">
											<div class="row mx-0 align-items-center">
												<app-skeleton-loader
												[height]="'21px'"
												[width]="'93px'"
												></app-skeleton-loader>
											</div>
											<div>
												<span class="font-12 short-file-name-desc">
													<app-skeleton-loader
														[height]="'35px'"
														[width]="'93px'"
														></app-skeleton-loader>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</slide>
				</carousel>
  
			</div>

			<div *ngIf="aBuckets.length > 0 && !searchInProgress && !isSkeletonLoading">
				<carousel
					[interval]="'false'"
					[itemsPerSlide]="itemsPerSlide"
					[showIndicators]="false"
					[noWrap]="true"
					carouselActiveSlide>
					<slide
						class="m-0"
						*ngFor="
							let bucket of aBuckets;
							let i = index; trackBy: trackByFn
						">
						<div
							class="slide card mx-3 team-member-slider mb-0 clickable relative-container position-relative"
							[ngClass]="buckets.includes(bucket.id) ? 'border-purple' : ''"
							(click)="selectBucket(bucket)"
							>
							<div class="card-body p-3 card-padding"
								[popover]="selectBucketMsg" 
								container="body" 
								triggers="mouseenter:mouseleave">
								<div class="row mx-0 justify-content-between w-100 d-flex">

									<div class="row mx-0 d-flex text-block-width">
										<div class="text-block-icon">
											<img src="assets/img/folder-icon-large.svg" alt="bucket-icon">
										</div>
										<div class="text-block-text">
											<span class="font-18 short-file-name short-file-name-title clickable" [popover]="bucketTitle" container="body" triggers="mouseenter:mouseleave click">
												{{bucket.name}}
											</span>
											<ng-template #bucketTitle>
												<div>
													{{bucket.name}}
												</div>
											</ng-template>
										</div>
									</div>
									<div
										class="text-right text-block-icon" (click)="$event.stopPropagation()">
										<div class="btn-group" dropdown container="body">
											<div class="mr-1"
												[ngClass]="{'check-icon': !buckets.includes(bucket.id)}"
												(click)="selectBucket(bucket)">
												<app-true-icon [type]="'selected'"
												*ngIf="buckets.includes(bucket.id)"></app-true-icon>
											</div>
											<app-gear-icon id="button-container"
											dropdownToggle
											app-gear-icon>
											</app-gear-icon>
											<ul
												id="dropdown-container"
												*dropdownMenu
												class="dropdown-menu custom-dropdown"
												role="menu"
												aria-labelledby="button-container">
												<li role="menuitem">
													<a
														class="dropdown-item text-grey"
														href="javascript:"
														(click)="initViewBucketModal(bucket, viewBucketModal)"
														*ngIf="
															(isClientUser ||
															(isSubClientUser && userId == bucket.created_by))
														">
														Edit Bucket
													</a>
												</li>
												<li role="menuitem">
													<a
														class="dropdown-item text-grey"
														href="javascript:"
														(click)="initDeleteBucketModal(bucket, deleteBucketModal)"
														*ngIf="
															isClientUser ||
															(isSubClientUser && userId == bucket.created_by)
														">
														Delete Bucket
													</a>
												</li>
												<li role="menuitem">
													<a
														class="dropdown-item text-grey"
														href="javascript:"
														(click)="initEditBucketModal(bucket, editBucketModal)"
														>
														View Bucket
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div>
									<hr class="margin-bottom-1-point-2-rem">
								</div>
								<div>
									<div class="row mx-0">
										<div>
											<img *ngIf="bucket?.last_bucket_asset && bucket?.assets_count > 0"
											class="file-img"
											[src]="bucket?.last_bucket_asset?.document_file?.full_path"
											alt=""
											onError="this.src='../../../../assets/img/img-grey-bg.png';" />
											<img
											class="file-img"
											src="../../../../assets/img/img-grey-bg.png"
											alt=""
											*ngIf="!bucket?.last_bucket_asset && bucket?.assets_count == 0" />
											<img
											class="file-img"
											src="../../../../assets/img/no-img-file.svg"
											alt=""
											*ngIf="!bucket?.last_bucket_asset && bucket?.assets_count > 0" />
										</div>
										<div class="data-custom-width padding-left-2-rem">
											<div class="row mx-0 align-items-center">
												<span class="font-14">
													{{bucket?.assets_count}}
												</span>
												<app-purple-folder-icon [popover]="filesTooltip" container="body" triggers="mouseenter:mouseleave click" class="ml-2">
												</app-purple-folder-icon>
												<ng-template #filesTooltip>
													<div *ngIf="bucket?.assets_count == 1">
														{{bucket?.assets_count}} File
													</div>
													<div *ngIf="bucket?.assets_count > 1 || bucket?.assets_count == 0">
														{{bucket?.assets_count}} Files
													</div>
												</ng-template>
												<span class="font-14 ml-2">
													{{bucket?.project_shared_buckets_count}}
												</span>
												<app-purple-projects-icon class="ml-2" [popover]="projectsTooltip" container="body" triggers="mouseenter:mouseleave click">
												</app-purple-projects-icon>
												<ng-template #projectsTooltip>
													<div *ngIf="bucket?.project_shared_buckets_count == 1">
														Used in {{bucket?.project_shared_buckets_count}} project
													</div>
													<div *ngIf="bucket?.project_shared_buckets_count > 1 || bucket?.project_shared_buckets_count == 0">
														Used in {{bucket?.project_shared_buckets_count}} projects
													</div>
												</ng-template>
											</div>
											<div class="pt-3">
												<span class="font-12 short-file-name-desc">
													{{bucket?.description}}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<ng-template #selectBucketMsg>
							<div *ngIf="!buckets.includes(bucket.id)">
								Click to add brand bucket
							</div>
							<div *ngIf="buckets.includes(bucket.id)">
								Click to remove brand bucket
							</div>
						</ng-template>
					</slide>
					
				</carousel>
			</div>
		</div>
	</div>
</div>

<ng-template #addBucketModal>
	<div class="modal-header">
		<h4 class="modal-title text-primary">Add New Brand Bucket</h4>
		<img
			class="close clickable m-0 p-2 mt-1"
			[src]="'../../../../../' + closeIcon"
			alt=""
			(click)="modalAddBucket.hide();" />
	</div>
	<div class="modal-body">
		<div class="modal-body">
			<form
				(submit)="submitAddBucketForm($event)"
				*ngIf="valForm"
				[formGroup]="valForm"
				class="form-horizontal">
				<div class="form-group">
					<label class="">
						Brand Bucket name
						<em
							class="fa fa-info-circle clickable text-primary"
							[popover]="bucketTooltip"
							triggers="mouseenter:mouseleave click"></em>
						<ng-template #bucketTooltip>
							<div>
								A Brand Bucket contains all the assets you have
								for your or your client's brand. Examples: Logo,
								Branding Guide, Colors, etc.
							</div>
						</ng-template>
					</label>
					<div class="">
						<input
							[ngClass]="{
								'border-red':
									valForm.controls['name'].hasError(
										'required'
									) &&
									(valForm.controls['name'].dirty ||
										valForm.controls['name'].touched) &&
									isValidationShown
							}"
							class="form-control"
							formControlName="name"
							placeholder="Name"
							type="text" />
						<div
							*ngIf="
								valForm.controls['name'].hasError('required') &&
								(valForm.controls['name'].dirty ||
									valForm.controls['name'].touched) &&
								isValidationShown
							"
							class="text-danger mt-1">
							Hold on, you need to add a name for your bucket.
						</div>
					</div>
				</div>
				<div class="form-group">
					<label class="">Brand Bucket description</label>
					<textarea
						class="form-control"
						formControlName="description"
						name="description"
						rows="5"
						placeholder="Details about the brand associated with this bucket"></textarea>
					<div
						*ngIf="
							valForm.controls['description'].hasError(
								'required'
							) &&
							(valForm.controls['description'].dirty ||
								valForm.controls['description'].touched)
						"
						class="text-danger">
						Required
					</div>
				</div>
				<div class="form-group mt-2">
					<label class="">Upload file(s)</label>
					<div class="row">
						<div class="col-md-12">
							<div class="custom-file mb-3">
								<input
									#bucketFile
									type="file"
									class="custom-file-input"
									id="project_file"
									(change)="onFileSelected($event)"
									multiple="multiple" />
								<label
									class="custom-file-label"
									for="project_file">
									Choose file
								</label>
							</div>
						</div>
						<div class="text-right col-12 mb-3">
							<a
								href="javascript:"
								(click)="addFile(bucketFile)"
								class="text-underline">
								<em class="fas fa-plus mr-1"></em>
								Add another file
							</a>
						</div>
						<div
							*ngFor="let oFile of oFiles; let j = index"
							class="col-12">
							<button
								type="button"
								class="align-items-center btn btn-block btn-labeled btn-secondary d-flex mb-3">
								<span class="btn-label">
									<em class="fa fa-file text-primary"></em>
								</span>
								<span class="text-left mr-auto">
									{{ oFile.name }}
								</span>
								<span
									class="btn-label btn-label-right"
									(click)="removeFile(j)">
									<em class="fa fa-times"></em>
								</span>
							</button>
						</div>
						<div class="col-md-12 text-center">
							<div
								class="text-center"
								ng2FileDrop
								[ngClass]="{
									'nv-file-over': oBaseDropZoneOver
								}"
								(fileOver)="fileOverBase($event)"
								(onFileDrop)="onFileDropped($event)"
								[uploader]="oUploader['file']"
								class="box-placeholder my-drop-zone">
								DRAG & DROP
							</div>
						</div>
					</div>
					<div class="my-2" *ngIf="bShowProgressBar">
						<progressbar
							[animate]="false"
							[value]="progressBarPercentage"
							type="success">
							<b>{{ progressBarPercentage }}%</b>
						</progressbar>
					</div>
				</div>
				<button
					class="mr-1 btn btn-labeled btn-primary start-project active mb-2 float-md-right mt-2 mt-md-0"
					type="submit"
					[disabled]="disableAddBucketBtn">
					<span class="btn-label">
						<i class="fas fa-plus"></i>
					</span>
					Add New Brand Bucket
				</button>
			</form>
		</div>
	</div>
</ng-template>

<ng-template #editBucketModal>
	<div class="modal-header" *ngIf="oSelectedBucket">
		<h4 class="modal-title text-primary overflow-wrap-anywhere">
			Brand Bucket: {{ oSelectedBucket.name }}
		</h4>
		<button
			(click)="modalEditBucket.hide()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="modal-body">
			<form
				(submit)="submitEditBucketForm($event)"
				*ngIf="valEditForm"
				[formGroup]="valEditForm"
				class="form-horizontal">
				<div class="form-group">
					<label class="">Brand Bucket name</label>
					<div class="">
						<div class="form-control height-100">
							{{oSelectedBucket.name}}
						</div>
					</div>
				</div>
				<div class="form-group">
					<label class="">Brand Bucket description</label>
					<div class="">
						<div class="form-control height-100">
							{{oSelectedBucket.description}}
						</div>
					</div>
				</div>
			</form>

			<div *ngIf="aBucketAssets && aBucketAssets?.length > 0">
				<div class="p mb-2 mt-3 text-primary">Uploaded file(s)</div>
				<hr />
				<div class="row">
					<div
						*ngFor="let asset of aBucketAssets"
						class="col-lg-4 col-md-6 col-xl-3 mb-3">
						<div class="border bb0" *ngIf="asset['document_file']">
							<div class="position-relative">
								<div
									class="bucket-image-wrapper d-flex justify-content-center"
									*ngIf="
										asset['document_file'][
											'file_mime_type'
										].lastIndexOf('image/', 0) === 0
									">
									<img
										loading="lazy"
										class=""
										src="{{
											asset.document_file.full_path
										}}"
										onError="this.src='../../../assets/img/dummy.png'" />
								</div>
								<img
									*ngIf="
										asset['document_file'][
											'file_mime_type'
										].lastIndexOf('image/', 0) !== 0
									"
									class="asset-img"
									loading="lazy"
									src="../../../../assets/img/file.png" />
								<div class="d-flex asset-actions py-2">
									
									<a
										(click)="
											_documentService.downloadFile(
												asset.document_file.id
											)
										"
										class="pl-2"
										href="javascript:">
										<em class="fa fa-download fa-fw"></em>
										Download
									</a>
								</div>
							</div>
						</div>
						<div
							class="border bt0 p-2 overflow-wrap-anywhere bucket-content-wrapper">
							<div class="p bucket-name-wrapper">
								{{
									asset.name
										? asset.name
										: asset.document_file &&
										  asset.document_file.filename
										? asset.document_file.filename
										: '-'
								}}
							</div>
							<div class="p bucket-description-wrapper">
								{{
									asset.description ? asset.description : '-'
								}}
							</div>
							<div class="p">
								<span class="ml-auto">
									{{ asset.formattedDate }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="clearfix">
					<div class="float-left lead">
						{{ oAssetsPagination['totalRecords'] }} File{{
							oAssetsPagination['totalRecords'] > 1 ? 's' : ''
						}}
					</div>
					<div class="float-right">
						<pagination
							[totalItems]="oAssetsPagination['totalRecords']"
							[itemsPerPage]="oAssetsPagination['itemsPerPage']"
							(pageChanged)="onPageChanged($event)"
							[maxSize]="5"></pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #deleteBucketModal>
	<div class="modal-header" *ngIf="oSelectedBucket">
		<h4 class="modal-title text-primary">
			Delete Brand Bucket: {{ oSelectedBucket.name }}
		</h4>
		<button
			(click)="modalDeleteBucket.hide()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div *ngIf="oSelectedBucket" class="modal-body">
		<div class="">
			<div class="">
				Are you sure you want to delete this Brand Bucket?
			</div>
		</div>
	</div>
	<div class="pb-4 pr-4">
		<div class="text-right">
			<button
				(click)="deleteBucket(oSelectedBucket)"
				class="btn btn-danger ml-2"
				data-dismiss="modal"
				type="button"
				[disabled]="disableDeleteBucketBtn">
				Delete
			</button>
		</div>
	</div>
</ng-template>

<ng-template #deleteBucketAssetModal>
	<div class="modal-header" *ngIf="oSelectedBucketAsset">
		<h4 class="modal-title text-primary">
			Delete Brand Bucket Asset:
			{{
				oSelectedBucketAsset.name
					? oSelectedBucketAsset.name
					: oSelectedBucketAsset?.document_file.filename
			}}
		</h4>
		<button
			(click)="modalDeleteBucketAsset.hide()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div *ngIf="oSelectedBucket" class="modal-body">
		<div class="">
			<div class="">
				Are you sure you want to delete this Brand Bucket Asset?
			</div>
		</div>
	</div>
	<div class="pb-4 pr-4">
		<div class="text-right">
			<button
				(click)="deleteBucketAsset()"
				class="btn btn-danger ml-2"
				data-dismiss="modal"
				type="button">
				Delete
			</button>
		</div>
	</div>
</ng-template>

<ng-template #viewBucketModal>
	<div class="modal-header" *ngIf="oSelectedBucket">
		<h4 class="modal-title text-primary overflow-wrap-anywhere">
			Brand Bucket: {{ oSelectedBucket.name }}
		</h4>
		<button
			(click)="hideViewBucketModal()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="modal-body">
			<form
				(submit)="submitEditBucketForm($event)"
				*ngIf="valEditForm"
				[formGroup]="valEditForm"
				class="form-horizontal">
				<div class="form-group">
					<label class="">Brand Bucket name</label>
					<div class="">
						<input
							class="form-control"
							formControlName="name"
							placeholder="Name"
							type="text" />
						<div
							*ngIf="
								valEditForm.controls['name'].hasError(
									'required'
								) &&
								(valEditForm.controls['name'].dirty ||
									valEditForm.controls['name'].touched)
									&& editBucketBtnClicked"
							class="text-danger">
							Please provide a name for your bucket!
						</div>
						<div *ngIf="valEditForm.get('name').hasError('whitespace') &&
						    !valEditForm.controls['name'].hasError('required') &&
							(valEditForm.controls['name'].dirty ||
							valEditForm.controls['name'].touched)
							&& editBucketBtnClicked" class="text-danger">
							Please enter valid data
						</div>
					</div>
				</div>
				<div class="form-group">
					<label class="">Brand Bucket description</label>
					<div class="">
						<textarea
							class="form-control"
							formControlName="description"
							name="description"
							rows="5"
							placeholder="Details about the brand associated with this bucket"></textarea>
						<div
							*ngIf="
								valEditForm.controls['description'].hasError(
									'required'
								) &&
								(valEditForm.controls['description'].dirty ||
									valEditForm.controls['description'].touched)
							"
							class="text-danger">
							Required
						</div>
					</div>
				</div>
				<div class="form-group mt-2">
					<label class="">Upload file(s)</label>
					<div class="row">
						<div class="col-md-12">
							<div class="custom-file mb-3">
								<input
									#bucketFile
									type="file"
									class="custom-file-input"
									id="project_file"
									(change)="onFileSelected($event)"
									multiple="multiple" />
								<label
									class="custom-file-label"
									for="project_file">
									Choose file
								</label>
							</div>
						</div>
						<div class="text-right col-12 mb-3">
							<a
								href="javascript:"
								(click)="addFile(bucketFile)"
								class="text-underline">
								<em class="fas fa-plus mr-1"></em>
								Add another file
							</a>
						</div>
						<div
							*ngFor="let oFile of oFiles; let j = index"
							class="col-12">
							<button
								type="button"
								class="align-items-center btn btn-block btn-labeled btn-secondary d-flex mb-3">
								<span class="btn-label">
									<em class="fa fa-file text-primary"></em>
								</span>
								<span
									class="text-left mr-auto overflow-wrap-anywhere">
									{{ oFile.name }}
								</span>
								<span
									class="btn-label btn-label-right"
									(click)="removeFile(j)">
									<em class="fa fa-times"></em>
								</span>
							</button>
						</div>
						<div class="col-md-12 text-center">
							<div
								class="text-center"
								ng2FileDrop
								[ngClass]="{
									'nv-file-over': oBaseDropZoneOver
								}"
								(fileOver)="fileOverBase($event)"
								(onFileDrop)="onFileDropped($event)"
								[uploader]="oUploader['file']"
								class="box-placeholder my-drop-zone">
								DRAG & DROP
							</div>
						</div>
					</div>
					<div class="my-2" *ngIf="bShowProgressBar">
						<progressbar
							[animate]="false"
							[value]="progressBarPercentage"
							type="success">
							<b>{{ progressBarPercentage }}%</b>
						</progressbar>
					</div>
				</div>
				<div class="text-right">
					<button
						class="btn btn-primary mb-2"
						type="submit"
						[disabled]="disableEditBucketBtn">
						Submit
					</button>
				</div>
			</form>

			<div *ngIf="aBucketAssets && aBucketAssets?.length > 0">
				<div class="p mb-2 mt-3 text-primary">Uploaded file(s)</div>
				<hr />
				<div class="row">
					<div
						*ngFor="let asset of aBucketAssets"
						class="col-lg-4 col-md-6 col-xl-3 mb-3">
						<div class="border bb0" *ngIf="asset['document_file']">
							<div class="position-relative">
								<div
									class="bucket-image-wrapper d-flex justify-content-center"
									*ngIf="
										asset['document_file'][
											'file_mime_type'
										].lastIndexOf('image/', 0) === 0
									">
									<img
										loading="lazy"
										class=""
										src="{{
											asset.document_file.full_path
										}}"
										onError="this.src='../../../assets/img/dummy.png'" />
								</div>
								<img
									*ngIf="
										asset['document_file'][
											'file_mime_type'
										].lastIndexOf('image/', 0) !== 0
									"
									class="asset-img"
									loading="lazy"
									src="../../../../assets/img/file.png" />
								<div class="d-flex asset-actions py-2">
									<a
										(click)="
											initEditBucketAssetModal(
												asset,
												editBucketAssetModal
											)
										"
										*ngIf="
											isClientUser ||
											(isSubClientUser &&
												userId == asset.created_by)
										"
										class="pl-2"
										href="javascript:">
										<em class="fa fa-pencil-alt fa-fw"></em>
										Edit
									</a>
									<a
										(click)="
											_documentService.downloadFile(
												asset.document_file.id
											)
										"
										class="pl-2"
										href="javascript:">
										<em class="fa fa-download fa-fw"></em>
										Download
									</a>
									<a
										(click)="
											initDeleteBucketAssetModal(
												asset,
												deleteBucketAssetModal
											)
										"
										*ngIf="
											isClientUser ||
											(isSubClientUser &&
												userId == asset.created_by)
										"
										class="pl-2"
										href="javascript:">
										<em class="fa fa-trash-alt fa-fw"></em>
										Delete
									</a>
								</div>
							</div>
						</div>
						<div
							class="border bt0 p-2 overflow-wrap-anywhere bucket-content-wrapper">
							<div class="p bucket-name-wrapper">
								{{
									asset.name
										? asset.name
										: asset.document_file &&
										  asset.document_file.filename
										? asset.document_file.filename
										: '-'
								}}
							</div>
							<div class="p bucket-description-wrapper">
								{{
									asset.description ? asset.description : '-'
								}}
							</div>
							<div class="p">
								<span class="ml-auto">
									{{ asset.formattedDate }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="clearfix">
					<div class="float-left lead">
						{{ oAssetsPagination['totalRecords'] }} File{{
							oAssetsPagination['totalRecords'] > 1 ? 's' : ''
						}}
					</div>
					<div class="float-right">
						<pagination
							[totalItems]="oAssetsPagination['totalRecords']"
							[itemsPerPage]="oAssetsPagination['itemsPerPage']"
							(pageChanged)="onBucketAssetsPageChanged($event)"
							[maxSize]="5"></pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #editBucketAssetModal>
	<div class="modal-header" *ngIf="oSelectedBucketAsset">
		<h4 class="modal-title text-primary">
			Update Brand Bucket Asset:
			{{
				oSelectedBucketAsset.name
					? oSelectedBucketAsset.name
					: oSelectedBucketAsset?.document_file.filename
			}}
		</h4>
		<button
			(click)="modalEditBucketAsset.hide()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="modal-body">
			<div class="row">
				<div class="col-md-4">
					<div class="">
						<div class="position-relative">
							<div
							 class="bucket-image-wrapper d-flex justify-content-center">
								<img
									*ngIf="
										oSelectedBucketAsset['document_file'][
											'file_mime_type'
										].lastIndexOf('image/', 0) === 0
									"
									class="modal-imgs"
									loading="lazy"
									src="{{
										oSelectedBucketAsset.document_file.full_path
									}}"
									onError="this.src='../../../assets/img/dummy.png'" />
								<img
									*ngIf="
										oSelectedBucketAsset['document_file'][
											'file_mime_type'
										].lastIndexOf('image/', 0) !== 0
									"
									class="modal-imgs"
									loading="lazy"
									src="../../../../assets/img/file.png"
									/>
							</div>
							<div class="d-flex asset-actions py-2">
								<a
									(click)="
										_documentService.downloadFile(
											oSelectedBucketAsset.document_file
												.id
										)
									"
									class="pl-2"
									href="javascript:">
									<em class="fa fa-download fa-fw"></em>
									Download
								</a>
								<a
									(click)="
										initDeleteBucketAssetModal(
											oSelectedBucketAsset,
											deleteBucketAssetModal
										)
									"
									*ngIf="
										isClientUser ||
										(isSubClientUser &&
											userId ==
												oSelectedBucketAsset.created_by)
									"
									class="pl-2"
									href="javascript:">
									<em class="fa fa-trash-alt fa-fw"></em>
									Delete
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-8">
					<form
						(submit)="submitEditBucketAssetForm($event)"
						*ngIf="valBucketAssetEditForm"
						[formGroup]="valBucketAssetEditForm"
						class="form-horizontal">
						<div class="form-group">
							<label class="">Asset description</label>
							<div class="">
								<textarea
									class="form-control"
									formControlName="description"
									name="description"
									rows="7"
									placeholder="description"></textarea>
								<div
									*ngIf="
										valBucketAssetEditForm.controls['description'].hasError(
											'required'
										) &&
										(valBucketAssetEditForm.controls['description'].dirty ||
										valBucketAssetEditForm.controls['description'].touched)
										&& updateAssetBtnClicked"
									class="text-danger">
									Please provide a description for your asset!
								</div>
								<div *ngIf="valBucketAssetEditForm.get('description').hasError('whitespace') &&
									!valBucketAssetEditForm.controls['description'].hasError('required') &&
									(valBucketAssetEditForm.controls['description'].dirty ||
									valBucketAssetEditForm.controls['description'].touched)
									&& updateAssetBtnClicked" class="text-danger">
									Please enter valid data
								</div>
							</div>
						</div>
						<button
							class="btn btn-primary float-right"
							type="submit"
							[disabled]="
								disableEditBucketAssetBtn
							">
							Update Asset
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>