import {
	Component,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LocalStorageService } from '../../../../services/localstorage.service';
import { ProjectAssignedUserService } from '../../../../services/project/project-assigned-user.service';
import { ProjectService } from '../../../../services/project/project.service';
import { UserService } from '../../../../services/user/user.service';
import { ToasterService } from 'angular2-toaster';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';
import { UserInviteService } from '../../../../services/user/user-invite.service';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-sub-user-info',
	templateUrl: './sub-user-info.component.html',
	styleUrls: ['./sub-user-info.component.scss'],
})
export class SubUserInfoComponent {
	@Input() itemsPerSlide = 5;
	@Input() selectedSubClientList = [];
	@Output() subUserDetail: any = new EventEmitter();
	subClientUserList = [];
	modalNewInviteUserModal: BsModalRef;
	closeIcon = FlocksyUtil.closeIcon;
	valForm: FormGroup;
	addSubUserImg = FlocksyUtil.addSubUserImg;
	invitedUserList = [];
	permissions = [
		{
			label: 'Manage All Projects',
			id: 'can_manage_all_projects',
		},
		{
			label: 'View All Projects',
			id: 'can_view_projects',
		},
		{
			label: 'Manage Their Projects',
			id: 'can_manage_own_projects',
		},
		{
			label: 'View All & Manage Their Projects',
			id: 'can_view_manage_own_projects',
		},
	];
	permissionsData = {
		permissions: {
			can_view_projects: false,
			can_manage_all_projects: false,
			can_manage_own_projects: false,
			can_view_manage_own_projects: false,
		},
	};
	isInviteValidationShow: boolean;

	constructor(
		private _projectService: ProjectService,
		private _userService: UserService,
		private _localStorageService: LocalStorageService,
		private _formBuilder: FormBuilder,
		private _toasterService: ToasterService,
		public _userInviteService: UserInviteService,
		private _bsModalService: BsModalService
	) {
		this.initForm();
		this.subClientUserList.push({});
		// this.retrieveSubClientUser();
	}

	initForm() {
		this.valForm = this._formBuilder.group({
			name: [null, Validators.required],
			email: [null, [Validators.required, Validators.email]], // Using built-in Validators.email for simplicity
			permissions: ['', Validators.required],
		});
	}

	onPermissionsSelected(permissionName) {
		if (this.permissionsData.permissions.hasOwnProperty(permissionName)) {
			Object.keys(this.permissionsData.permissions).forEach((key) => {
				this.permissionsData.permissions[key] = false;
			});
			this.permissionsData.permissions[permissionName] = true;
		}
	}

	retrieveSubClientUser() {
		// forkJoin({
		// 	inviteList: this._userInviteService.index(
		// 		this._localStorageService.getUserId()
		// 	),
		// 	subClientList: this._projectService.getSubClientUser(
		// 		this._localStorageService.getUserId()
		// 	),
		// }).subscribe(({ inviteList, subClientList }) => {
		// 	this.subClientUserList = [];
		// 	let inviteUser = [];
		// 	const processedInviteList = inviteList.data.map((obj) => ({
		// 		...obj,
		// 		client_list_type: 1,
		// 	}));
		// 	this.invitedUserList.forEach((resData) => {
		// 		let index = processedInviteList.findIndex((res) => {
		// 			return resData?.email == res?.email;
		// 		});
		// 		if (index != -1) {
		// 			inviteUser.push(processedInviteList[index]);
		// 		}
		// 	});
		// 	const processedSubClientList = subClientList.data.map((obj) => ({
		// 		...obj,
		// 		client_list_type: 2,
		// 	}));
		// 	const combinedList = [...inviteUser, ...processedSubClientList];
		// 	setTimeout(() => {
		// 		this.subClientUserList = combinedList;
		// 		this.subClientUserList.push({});
		// 	});
		// });

		this._userInviteService
			.index(this._localStorageService.getUserId())
			.subscribe((inviteList) => {
				let inviteUser = [];
				const processedInviteList = inviteList.data.map((obj) => ({
					...obj,
					client_list_type: 1,
				}));
				this.invitedUserList.forEach((resData) => {
					let index = processedInviteList.findIndex((res) => {
						return resData?.email == res?.email;
					});
					if (index != -1) {
						inviteUser.push(processedInviteList[index]);
					}
				});
				this.subClientUserList = inviteUser;
				this.subClientUserList.push({});
			});
	}

	submitForm($ev, value: any) {
		this.isInviteValidationShow = true;
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}
		let oParam = {
			name: this.valForm.value.name,
			email: this.valForm.value.email,
			permissions: this.permissionsData.permissions,
		};
		this._userService
			.storeUsersInvite(this._localStorageService.getUserId(), oParam)
			.subscribe((data) => {
				this.invitedUserList.push(oParam);
				this.valForm.reset();
				this.modalNewInviteUserModal.hide();
				this._toasterService.pop('success', 'Invite sent!', 'Go team!');
				this.retrieveSubClientUser();
				this.isInviteValidationShow = false;
			});
	}

	initNewInviteUserModal(template: TemplateRef<any>) {
		this.isInviteValidationShow = false;
		this.modalNewInviteUserModal = this._bsModalService.show(
			template,
			Object.assign({}, {}, {})
		);
	}

	selectSubClient(index) {
		this.selectedSubClientList.push(this.subClientUserList[index].id);
		if (this.selectedSubClientList) {
			this.subUserDetail.emit(this.selectedSubClientList);
		}
	}

	unSelectSubClient(index) {
		if (this.selectedSubClientList) {
			let findIndex = this.selectedSubClientList.findIndex((res) => {
				return res == this.subClientUserList[index].id;
			});
			this.selectedSubClientList.splice(findIndex, 1);
			this.subUserDetail.emit(this.selectedSubClientList);
		}
	}
}
