<div class="checkbox-wrapper">
	<label class="checkbox-container">
		<input
			type="checkbox"
			id="{{ id }}"
			[checked]="checked"
			(change)="onCheckboxChange($event)" />
		<span class="checkmark"></span>
	</label>
</div>
