<div class="sub-user-info">
	<div *ngIf="subClientUserList?.length">
		<div class="row">
			<div class="col-12">
				<div class="p mb-3 d-flex">
					<p class="mb-0 text-color-gray">Share this project</p>
					<em
						placement="auto"
						[popover]="teamMemberTooltip"
						triggers="mouseenter:mouseleave click"
						class="fa fa-info-circle clickable ml-1 f-18"></em>
					<ng-template #teamMemberTooltip>
						Share this project
					</ng-template>
				</div>
			</div>

			<div class="col-12 mb-4">
				<div class="sub-category-info-wrapper">
					<carousel
						[interval]="'false'"
						[itemsPerSlide]="itemsPerSlide"
						[showIndicators]="false"
						[noWrap]="true">
						<slide
							class="m-0"
							*ngFor="
								let userData of subClientUserList;
								let i = index
							">
							<div
								*ngIf="subClientUserList?.length - 1 > i"
								class="slide card mx-3 mb-0 clickable h-100"
								[ngClass]="{
									active: selectedSubClientList?.includes(
										userData?.id
									)
								}"
								(click)="
									selectedSubClientList?.includes(
										userData?.id
									)
										? unSelectSubClient(i)
										: selectSubClient(i)
								">
								<div
									class="subcategory-selected-icon"
									*ngIf="
										selectedSubClientList?.includes(
											userData?.id
										)
									">
									<app-true-icon
										[type]="'selected'"></app-true-icon>
								</div>
								<div class="card-body text-center">
									<img
										class="mb-1 img-fluid rounded-circle thumb66"
										src="{{
											userData.profile_picture?.full_path
										}}"
										alt=""
										onError="this.src='../../../assets/img/user-placeholder.png';" />
									<p class="mb-1">
										{{ userData?.name }}
									</p>
									<div
										class="btn-group disabled-text-wrapper"
										container="body">
										<span
											id="button-container"
											class="clickable">
											<span
												*ngIf="
													userData?.permissions
														?.can_manage_all_projects
												">
												Manage All Projects
											</span>
											<span
												*ngIf="
													userData?.permissions
														?.can_view_projects
												">
												View All Projects
											</span>
											<span
												*ngIf="
													userData?.permissions
														?.can_manage_own_projects
												">
												Manage Their Projects
											</span>
											<span
												*ngIf="
													userData?.permissions
														?.can_view_manage_own_projects
												">
												View & Manage Their Projects
											</span>
										</span>
										<span
											*ngIf="
												userData?.status != 0 &&
												!userData?.status
											">
											Invited
										</span>
									</div>
								</div>
							</div>
							<div
								*ngIf="subClientUserList.length - 1 == i"
								class="slide card mx-3 mb-0 clickable">
								<div
									(click)="
										initNewInviteUserModal(
											newInviteUserModal
										)
									"
									class="card-body text-center mt-1">
									<img
										class="mb-3 img-fluid rounded-circle thumb66"
										[src]="
											'../../../../../' + addSubUserImg
										"
										alt=""
										onError="this.src='../../../assets/img/user-placeholder.png';" />
									<p class="mb-1">Add user</p>
								</div>
							</div>
						</slide>
					</carousel>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #newInviteUserModal>
	<div class="modal-header">
		<p class="modal-title f-24">
			Invite users
			<em
				placement="auto"
				[popover]="newInviteTooltip"
				triggers="mouseenter:mouseleave click"
				class="fa fa-info-circle info-circle-wrapper clickable ml-1 f-18"></em>
		</p>
		<ng-template #newInviteTooltip>
			<div>
				A user is someone who can view and/or help manage projects in
				your account.
			</div>
		</ng-template>
		<img
			class="close clickable m-0 p-2 mt-1"
			[src]="'../../../../../' + closeIcon"
			alt=""
			(click)="modalNewInviteUserModal.hide(); valForm.reset()" />
	</div>
	<div class="modal-body">
		<div class="container-fluid container invite-userForm">
			<form
				(submit)="submitForm($event, valForm.value)"
				*ngIf="valForm"
				[formGroup]="valForm"
				class="form-validate">
				<div class="form-group">
					<div class="label my-3">
						Full name:
						<span class="text-danger ng-star-inserted">*</span>
					</div>
					<div
						class="input-group mb-2"
						(click)="isInviteValidationShow = false"
						[ngClass]="{
							'border-red':
								valForm.controls['name'].hasError('required') &&
								(valForm.controls['name'].dirty ||
									valForm.controls['name'].touched) &&
								isInviteValidationShow
						}">
						<input
							autocomplete="off"
							formControlName="name"
							name="name"
							placeholder="Full name"
							type="text"
							class="form-control border-right-0" />
						<div class="input-group-append">
							<span
								class="input-group-text text-muted bg-transparent border-left-0">
								<em class="fa fa-user"></em>
							</span>
						</div>
					</div>
					<div
						*ngIf="
							valForm.controls['name'].hasError('required') &&
							(valForm.controls['name'].dirty ||
								valForm.controls['name'].touched) &&
							isInviteValidationShow
						"
						class="text-danger">
						Enter the full name of the user.
					</div>
				</div>
				<div class="form-group">
					<div class="label mt-4 mb-3">
						Enter the email of the user you want to add to your
						account.
						<span class="text-danger ng-star-inserted">*</span>
					</div>
					<div
						class="input-group mb-2"
						(click)="isInviteValidationShow = false"
						[ngClass]="{
							'border-red':
								valForm.controls['email'].hasError(
									'required'
								) &&
								(valForm.controls['email'].dirty ||
									valForm.controls['email'].touched) &&
								isInviteValidationShow
						}">
						<input
							autocomplete="off"
							class="form-control border-right-0"
							formControlName="email"
							name="email"
							placeholder="Email address"
							type="email" />
						<div class="input-group-append">
							<span
								class="input-group-text text-muted bg-transparent border-left-0">
								<em class="fa fa-envelope"></em>
							</span>
						</div>
					</div>
					<div
						*ngIf="
							valForm.controls['email'].hasError('required') &&
							(valForm.controls['email'].dirty ||
								valForm.controls['email'].touched) &&
							isInviteValidationShow
						"
						class="text-danger">
						Enter a valid email address.
					</div>
				</div>
				<div class="form-group">
					<div class="label mt-4 mb-3">
						Select the permissions for the user.
						<span class="text-danger ng-star-inserted">*</span>
					</div>
					<div
						class="input-group mb-2"
						(click)="isInviteValidationShow = false"
						[ngClass]="{
							'border-red':
								valForm.controls['permissions'].hasError(
									'required'
								) &&
								(valForm.controls['permissions'].dirty ||
									valForm.controls['permissions'].touched) &&
								isInviteValidationShow
						}">
						<select
							formControlName="permissions"
							(change)="
								onPermissionsSelected($event.target.value)
							"
							class="form-control border-right-0">
							<option value="">Select Permissions</option>
							<option
								*ngFor="let data of permissions"
								[value]="data?.id">
								{{ data?.label }}
							</option>
						</select>
						<div class="input-group-append">
							<span
								class="input-group-text text-muted bg-transparent border-left-0">
								<em class="fa fa-key"></em>
							</span>
						</div>
					</div>
					<div
						*ngIf="
							valForm.controls['permissions'].hasError(
								'required'
							) &&
							(valForm.controls['permissions'].dirty ||
								valForm.controls['permissions'].touched) &&
							isInviteValidationShow
						"
						class="text-danger">
						Select your desired permission.
					</div>
				</div>

				<div class="row">
					<div class="col-md-8 text-left">
						<img
							src="../../../../../assets/img/use-invite-pop-up.png"
							class="img-fluid" />
					</div>
					<div class="col-md-4 text-right">
						<button class="btn invite-btn" type="submit">
							Invite User
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-template>
