import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '@services/login.service';
import { LocalStorageService } from '@services/localstorage.service';
import { ApiService } from '@services/common/api.service';
import { CommonService } from '@services/common/common.service';
import { CouponService } from '@services/coupon/coupon.service';
import { environment } from '@env/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { ClientSubClientService } from '@services/client/client-subclient.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ExternalScriptService } from '../../../services/common/external-script.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var profitwell;
declare var grecaptcha: any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	valForm: FormGroup;
	oError: object = {
		status: '',
	};
	guid: string;
	oCoupon: object = {
		applied: false,
		statusCode: '',
		message: '',
		data: {},
	};
	selectedPlan: object = {
		plan_id: 34,
		plan_cost: 420,
	};
	referralSource = '';
	referralCode;
	loginPageTriggerId =
		FlocksyUtil.authenticationTriggers()['traditional-login'];
	socialModalRef: BsModalRef;
	socialUrl;
	emailReq = false;
	inavlidEmail = false;
	submitted = false;
	isMicrosoftLoading = false;
	isGoogleLoading = false;
	passwordCode:string;
	recaptchaResponse:string;
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	appTitle: string;
	utmSource = '';

	constructor(
		public _settingsService: SettingsService,
		public _formBuilder: FormBuilder,
		public _commonService: CommonService,
		public _loginService: LoginService,
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		public _localStorageService: LocalStorageService,
		public _apiService: ApiService,
		public _couponService: CouponService,
		public _analyticsService: AnalyticsService,
		public _toasterService: ToasterService,
		private _clientSubClientService: ClientSubClientService,
		public _bsModalService: BsModalService,
		public _externalScriptService: ExternalScriptService,
		public _whiteLabelService: WhiteLabelService,
	) {
		this.initForm();
		this.setReferralCodeViaQueryString();
		this.setUtmSourceViaQueryString();

		if (this._activatedRoute.snapshot.paramMap.get('guid')) {
			this.guid = this._activatedRoute.snapshot.paramMap.get('guid');
			this.checkGuidLogin();
		}

		this.setCouponCodeViaQueryString();
		this.setPasswordCodeViaQueryString();
	}

	ngOnInit() {
		this._whiteLabelService.getAppTitle()
			.pipe(takeUntil(this.destroyed$))
			.subscribe((title: string) => {
				this.appTitle = title;
			})
		if (this._localStorageService.isUserLoggedIn()) {
			this.getAuthUser();
		} else{

		}


	}

	initForm() {
		this.valForm = this._formBuilder.group({
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			password: [null, Validators.required],
		});
	}

	setCouponCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('discount_code' in params) {
				this._localStorageService.setCouponCode(
					btoa(params['discount_code'])
				);
				this.onClickValidateCouponCode();
			}
		});
	}

	setReferralCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('r' in params) {
				this.referralSource = params['r'];
				this.referralCode = params['r'];
				this._localStorageService.setReferralCode(btoa(params['r']));
				this._localStorageService.setReferralAppliedDate(new Date());
				this.existsReferralCode();
			}
		});
	}

	setUtmSourceViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('utm_source' in params) {
				this.utmSource = params['utm_source'];
				this._localStorageService.setUtmSource(btoa(params['utm_source']));
			}
		});
	}

	getAuthUser() {
		this._apiService.getAuthUser({ tracker_flagged: 1 }).subscribe(
			(data) => {
				this._localStorageService.setCurrentUser(data.data);
				if (
					this._localStorageService.isClientUser() ||
					this._localStorageService.isSubClientUser()
				) {
					this.getRecentLink();
				}
				this._toasterService.pop('success', `Welcome back to ${this.appTitle}!`);
				this._commonService.redirectUser();
			},
			(err) => {}
		);
	}

	submitForm($ev, value: any) {
        $ev.preventDefault();
        this.submitted = true;

        if (this.valForm.valid) {
			for (let c in this.valForm.controls) {
				this.valForm.controls[c].markAsTouched();
			}

			if (!this.valForm.valid) {
				return false;
			}
            this.valForm.disable();
            var oAuthData = {
				grant_type: 'password',
				username: this.valForm.value.email,
				client_id: environment.ApiClientId,
				client_secret: environment.ApiClientSecret,
				referral_source: this._localStorageService.getReferralCode()
					? atob(this._localStorageService.getReferralCode())
					: null,
			};

			Object.assign(this.valForm.value, oAuthData);
			this.valForm.value['trigger_id'] = this.loginPageTriggerId;
			if(this.isWhiteLabelledUser) {
				this._whiteLabelService.getClientDomainDetails().subscribe((res) => {
					this._whiteLabelService.setWhiteLabelledUser(res.data);
					if(res.data?.is_active) {
						this.loginUser();
					}
					else {
						this._router.navigate(['/inactive']);
					}
				},
					(err) => {
						this._router.navigate(['/inactive']);
					}
				)
			}
			else {
				this.loginUser();
			}

        } else {
            this.submitted = true;
        }
    }

	loginUser() {
		this._loginService.oAuthToken(this.valForm.value).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
			},
			(err) => {
				this.valForm.enable();
				this.oError = err;
			}
		);
	}

	checkGuidLogin() {
		if (this._localStorageService.isUserLoggedIn()) {
			this._toasterService.pop(
				'error',
				"You're already logged in!",
				'Please refresh or open a new browser.'
			);
			return false;
		}

		let auth_data = {
			grant_type: 'password',
			username: 'test@test.com',
			password: 'test@123',
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			guid: this.guid,
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		};
		if(this.isWhiteLabelledUser) {
			this._whiteLabelService.getClientDomainDetails().subscribe((res) => {
					this._whiteLabelService.setWhiteLabelledUser(res.data);
					if(res.data?.is_active) {
						this.loginViaGuid(auth_data);
					}
					else {
						this._router.navigate(['/inactive']);
					}
				},
				(err) => {
					this._router.navigate(['/inactive']);
				}
			)
		}
		else {
			this.loginViaGuid(auth_data);
		}
	}

	loginViaGuid(authData) {
		this._loginService
			.loginGuid(this.guid, JSON.stringify(authData))
			.subscribe(
				(data) => {
					this._localStorageService.setAccessToken(data.access_token);
					this._localStorageService.setUserLoggedIn(true);
					this.getAuthUser();
				},
				(err) => {}
			);
	}

	existsReferralCode() {
		if (!this.referralCode) {
			return false;
		}
		this._apiService.existsReferralCode(this.referralCode).subscribe(
			(data) => {
				if ('coupon_code' in data.data) {
					this._localStorageService.setCouponCode(
						btoa(data.data['coupon_code'])
					);
					this.onClickValidateCouponCode();
				}
			},
			(err) => {}
		);
	}

	resetCouponData() {
		this.oCoupon = {
			applied: true,
			statusCode: '',
			message: '',
			data: {},
		};
	}

	onClickValidateCouponCode() {
		let coupon_code = this._localStorageService.getCouponCode();
		this.resetCouponData();
		if (coupon_code) {
			this._couponService.exists(atob(coupon_code)).subscribe(
				(data) => {
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = data.message;
					this._toasterService.pop('success', data.message);
					this.oCoupon['statusCode'] = data.status;
					this._localStorageService.setCouponCode(coupon_code);
					this._localStorageService.setCouponAppliedDate(new Date());
					this.oCoupon['data'] = data.data;
				},
				(err) => {
					this._localStorageService.setCouponCode('');
					this._localStorageService.setCouponAppliedDate('');
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = err.json().message;
					this.oCoupon['statusCode'] = err.json().status;
					this.oCoupon['data'] = {};
				}
			);
		}
	}

	getRecentLink() {
		let params = {
			identifier: 'recent_links',
		};
		this._clientSubClientService
			.getRecentLink(this._localStorageService.getUserId(), params)
			.subscribe((res) => {
				let routerHistoryDetail = [];
				if (res?.data?.details?.length) {
					res.data.details.forEach((resData) => {
						let data = {
							project_id: resData?.project_id,
							title: resData?.title,
							link: resData?.link,
						};
						routerHistoryDetail.push(data);
					});
					this._localStorageService.setItem(
						'routerHistory',
						routerHistoryDetail
					);
				}
			});
	}
	
	loginWithSocials(type) {
		const width = 600;
		const height = 600;
		const left = window.innerWidth / 2 - width / 2;
		const top = window.innerHeight / 2 - height / 2;
		const options = `width=${width},height=${height},left=${left},top=${top}`;

		if (type == 'google') {
			this.isGoogleLoading = true;
			this.isMicrosoftLoading = false;
			this._apiService.getGoogleCode().subscribe(
				data => {
					const width = 600;
					const height = 600;
					const left = (screen.width / 2) - (width / 2);
					const top = (screen.height / 2) - (height / 2);
					const redirectUri = environment.baseURL + '/social-logins/google/redirect'

					const authWindow = window.open(data.data.redirect_url, 'Google Login', `width=${width},height=${height},top=${top},left=${left}`);

					// Monitor the popup window for the response
					const pollTimer = window.setInterval(function() {
						if (authWindow.closed) {
							window.clearInterval(pollTimer);
							return;
						}
						try {
							const url = authWindow.location.href;
							if (url.indexOf(redirectUri) !== -1) {
								window.clearInterval(pollTimer);
								authWindow.close();
								this.handleGoogleAuthResponse(url);
							}
						} catch (e) {
							// Ignore cross-origin errors until we get the response
						}
					}.bind(this),
					100)
				}
			)
		} else if(type == 'microsoft') {
			this.isMicrosoftLoading = true;
			this.isGoogleLoading = false;
			this._apiService.getMicrosoftCode().subscribe(
				data => {
					const width = 600;
					const height = 600;
					const left = (screen.width / 2) - (width / 2);
					const top = (screen.height / 2) - (height / 2);
					const redirectUri = environment.baseURL + '/social-logins/microsoft/redirect'

					const authWindow = window.open(data.data.redirect_url, 'Microsoft Login', `width=${width},height=${height},top=${top},left=${left}`);

					// Monitor the popup window for the response
					const pollTimer = window.setInterval(function() {
						if (authWindow.closed) {
							window.clearInterval(pollTimer);
							return;
						}
						try {
							const url = authWindow.location.href;
							if (url.indexOf(redirectUri) !== -1) {
								window.clearInterval(pollTimer);
								authWindow.close();
								this.handleMicrosoftAuthResponse(url);
							}
						} catch (e) {
							// Ignore cross-origin errors until we get the response
						}
					}.bind(this),
					100)
				}
			)
		}
	}

	handleGoogleAuthResponse(url) {
		const urlObj = new URL(url);
		const code = urlObj.searchParams.get('code');
		this.handleSocialLoginToken('google', environment.oauthClients.google.clientId, environment.oauthClients.google.clientSecret, code, 'google')
	}

	handleMicrosoftAuthResponse(url) {
		const urlObj = new URL(url);
		const code = urlObj.searchParams.get('code');
		this.handleSocialLoginToken('microsoft', environment.oauthClients.microsoft.clientId, environment.oauthClients.microsoft.clientSecret, code, 'microsoft')
	}

	getCookieValue(name: string): string | null {
		const nameEQ = name + "=";
		const cookies = document.cookie.split(';');
	  
		for (let i = 0; i < cookies.length; i++) {
		  let cookie = cookies[i].trim();
		  if (cookie.indexOf(nameEQ) === 0) {
			return cookie.substring(nameEQ.length);
		  }
		}
		return null;
	}

	handleSocialLoginToken(providerName, providerClientId, providerClientSecret, code, type) {
		var oAuthData = {
		grant_type: providerName,
		client_id: providerClientId,
		client_secret: providerClientSecret,
		coupon_code: this._localStorageService.getCouponCode()
				? atob(this._localStorageService.getCouponCode())
				: '',
			coupon_applied_date:
				this._localStorageService.getCouponAppliedDate(),
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: this.getCookieValue('r')
				? this.getCookieValue('r')
				: null,
			utm_source: this._localStorageService.getUtmSource()
				? atob(this._localStorageService.getUtmSource())
				: this.getCookieValue('utm_source')
				? this.getCookieValue('utm_source')
				: null
		}

		// Dynamically add the correct auth_code property
		if (type === 'microsoft') {
			oAuthData['microsoft_auth_code'] = code;
		} else if (type === 'google') {
			oAuthData['google_auth_code'] = code;
		}

		this._loginService.oAuthToken(JSON.stringify(oAuthData)).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
				this.isGoogleLoading = false;
				this.isMicrosoftLoading = false;
			},
			(err) => {
				this._toasterService.pop('error', err.error.message);
				this._router.navigate(['/login']);
				this.isGoogleLoading = false;
				this.isMicrosoftLoading = false;
			}
		);
	}

	setPasswordCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe(async (params) => {
			if ('code' in params) {
				await this.reloadGoogleRecaptchaScript();
				this.passwordCode = params['code'];
				this.validateOauthPasswordCode();
			}
		});
	}

	validateOauthPasswordCode() {
		if (typeof grecaptcha !== 'undefined') {
			this.generateTokenGoogleRecaptcha();
		}

		let oAuthData = {
			'code': this.passwordCode,
			'google-recaptcha-response': this.recaptchaResponse,
		};


		console.log(oAuthData);
		// Object.assign(this.valForm.value, oAuthData);

		this._loginService.loginWithPasswordCode(oAuthData).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
			},
			(err) => {
				this.valForm.enable();
				this.oError = err;
			}
		);
	}

	async generateTokenGoogleRecaptcha() {
		const thisObject = this;
		await grecaptcha.ready(function () {
			grecaptcha
				.execute(environment.reCaptchaSiteKey, {
					action: 'appSignupPage',
				})
				.then(function (token) {
					// thisObject.assignRecaptchaToken(token);
					console.log(token);
					thisObject.recaptchaResponse = token;
				});
		});
	}

	async reloadGoogleRecaptchaScript() {
		try {
			const data = await this._externalScriptService.loadScript(
				'https://www.google.com/recaptcha/api.js?render=' + environment.reCaptchaSiteKey,
				'flocksy_recaptcha'
			);
			if (typeof grecaptcha == 'undefined') {
				console.log('not loaded');
			}
		} catch (error) {
			console.log(error);
		}
	}

	removeVisibilityGoogleRecaptcha() {
		const elements = document.getElementsByClassName('grecaptcha-badge');
		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	}

	destroyGoogleRecaptcha() {
		this.removeVisibilityGoogleRecaptcha();
		this._externalScriptService.removeScript('flocksy_recaptcha');
	}

	ngOnDestroy() {
		this.destroyGoogleRecaptcha();
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
