import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { LocalStorageService } from '@services/localstorage.service';
import { ApiService } from '@services/common/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { LoginService } from '@services/login.service';
import { SignupService } from '@services/signup/signup.service';
import { ToasterService } from 'angular2-toaster';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { WhiteLabelledUser } from '@services/interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-invite-signup',
	templateUrl: './invite-signup.component.html',
	styleUrls: ['../login/login.component.scss'],
})
export class InviteSignupComponent implements OnInit, OnDestroy {
	valForm: FormGroup;
	passwordForm: FormGroup;
	oError: object = {
		status: '',
	};
	token = '';
	oToken: object = {
		retrieved: false,
		code: '',
		message: '',
		data: {},
	};

	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();
	whiteLabelledUser: WhiteLabelledUser;
	destroyed$ = new Subject<void>();

	constructor(
		public _localStorageService: LocalStorageService,
		public _apiService: ApiService,
		public _loginService: LoginService,
		public _signupService: SignupService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		public _settingsService: SettingsService,
		public _formBuilder: FormBuilder,
		public _toasterService: ToasterService,
		public _whiteLabelService: WhiteLabelService,
	) {
		_activatedRoute.params
			.pipe(takeUntil(this.destroyed$))
			.subscribe((val) => {
			this.token = this._activatedRoute.snapshot.paramMap.get('token');
			this.showToken();
		});
		this._whiteLabelService.getWhiteLabelledUser$()
			.pipe(takeUntil(this.destroyed$))
			.subscribe((user) => {
				this.whiteLabelledUser = user;
			})
	}

	ngOnInit() {}

	initForm() {
		let password = new FormControl(
			'',
			Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(16),
			])
		);
		let certainPassword = new FormControl('', [Validators.required]);

		this.passwordForm = this._formBuilder.group(
			{
				password: password,
				confirm_password: certainPassword,
			},
			{
				validator: FlocksyValidator.equalTo(
					'password',
					'confirm_password'
				),
			}
		);

		this.valForm = this._formBuilder.group({
			name: [
				this.oToken['data']['name'],
				Validators.compose([
					Validators.required
				])
			],
			email: [
				{
					value: this.oToken['data']['email'],
					disabled: true,
				},
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			token: [this.token, Validators.required],
			phone: [null],
			passwordGroup: this.passwordForm,
			agree_terms: [this.isWhiteLabelledUser ? true:null, Validators.required],
		});
	}

	showToken() {
		if (this.token) {
			this._signupService.showToken(this.token).subscribe(
				(data) => {
					this.oToken['retrieved'] = true;
					this.oToken['code'] = data.status;
					this.oToken['data'] = data.data;
					this.initForm();
				},
				(err) => {
					this.oToken['retrieved'] = true;
					this.oToken['code'] = err.status;
					this.oToken['message'] = err.error.message;
				}
			);
		} else {
			this.oToken['code'] = 404;
			this.oToken['message'] = 'Token Not Found';
		}
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		for (let c in this.passwordForm.controls) {
			this.passwordForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}

		const oPost = this.valForm.getRawValue();
		Object.assign(oPost, this.valForm.value.passwordGroup);
		oPost['user_type_id'] = 5;

		this._signupService.inviteSignup(oPost).subscribe(
			(data) => {
				this._toasterService.pop('success', data.message);
				this.autoLoginAfterSignup(oPost['email'], oPost['password']);
				this.valForm.reset();
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	autoLoginAfterSignup(email, password) {
		var auth_data = {
			grant_type: 'password',
			username: email,
			password: password,
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			client_signup: 1,
		};

		this._loginService.oAuthToken(JSON.stringify(auth_data)).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	getAuthUser() {
		this._apiService.getAuthUser().subscribe((data) => {
			this._localStorageService.setCurrentUser(data.data);
			this.redirectUser();
		});
	}

	redirectUser() {
		this._router.navigate(['/projects/active']);
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
