import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';
import { ClientSubClientService } from '@services/client/client-subclient.service';
import { MessageService } from '@services/message/message.service';
import { ProjectPreferenceService } from '@services/project/project-preference.service';
import { HotjarService } from '@services/hotjar/hotjar.service';
import { SharedModule } from '../shared/shared.module';
import { DemoSignupOverlayModule } from '../shared/module/demo-signup/overlay/demo-signup.overlay.module';
import { CreativeLevelService } from '@services/user/creative-level.service';
import { DocumentService } from '@services/document/document.service';
import { SidebarService } from '@services/layout/siderbar.service';
import { ReportConcernModule } from '../shared/module/report-concern/report-concern.module';
import { ThonestService } from '@services/thonest.service';
import { NotificationService } from '@services/notification/notification.service';
import { NotificationsDetailviewModule } from '../shared/module/notifications-detailview/notifications-detailview.module';
import { EarningTimerModule } from '../shared/module/earning-timer/earning-timer.module';
import { LinkifyHtmlModule } from '../shared/pipes/linkify-html/linkify-html.pipe.module';
import { InnerHtmlPipeModule } from '../shared/pipes/innerhtml/innerhtml.pipe.module';
import { NewLinePipeModule } from '../shared/pipes/new-line/new-line.pipe.module';
import { ClientHeaderComponent } from './header/client-header/client-header.component';
import { ClientSidebarComponent } from './sidebar/client-sidebar/client-sidebar.component';
import { PaypalEmailComponent } from './header/components/paypal-email/paypal-email.component';
import { ShareTabsComponent } from './header/components/share-tabs/share-tabs.component';
import { ShareSocialMediaComponent } from './header/components/share-social-media/share-social-media.component';
import { ShareEmailComponent } from './header/components/share-email/share-email.component';
import { StatsTrackingComponent } from './header/components/stats-tracking/stats-tracking.component';
import { ReferralService } from './header/services/referral.service';
import { EmailSuccessComponent } from './header/components/email-success/email-success.component';
@NgModule({
	imports: [
		SharedModule,
		ReportConcernModule,
		DemoSignupOverlayModule,
		NotificationsDetailviewModule,
		EarningTimerModule,
		LinkifyHtmlModule,
		InnerHtmlPipeModule,
		NewLinePipeModule,
	],
	providers: [
		UserblockService,
		ClientSubClientService,
		DocumentService,
		MessageService,
		ProjectPreferenceService,
		CreativeLevelService,
		SidebarService,
		HotjarService,
		ThonestService,
		NotificationService,
		ReferralService
	],
	declarations: [
		LayoutComponent,
		SidebarComponent,
		UserblockComponent,
		HeaderComponent,
		NavsearchComponent,
		FooterComponent,
		ClientHeaderComponent,
		ClientSidebarComponent,
		PaypalEmailComponent,
		ShareTabsComponent,
		ShareSocialMediaComponent,
		ShareEmailComponent,
		StatsTrackingComponent,
		EmailSuccessComponent,
	],
	exports: [
		LayoutComponent,
		SidebarComponent,
		UserblockComponent,
		HeaderComponent,
		NavsearchComponent,
		FooterComponent,
	],
})
export class LayoutModule {}
