import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ProjectFinalizeService {
	constructor(private _customHttpService: CustomHttpService) {}

	getDefs(id, oFilter?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set(key, oFilter[key]);
		}

		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project/get/feedback_questions?project_id=' +
				id +
				(params.toString() ? '&' + params.toString() : '')
		);
	}
	getTypes(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project/get/feedback_questions?project_id=' +
				id +
				'&include[project_type_tags]=1'
		);
	}

	store(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/project/feedback_questions',
			oData
		);
	}
}
