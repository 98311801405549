export const PLAN_COUPONS = {
	Buy1Get1: 'BOGOF',
	Buy2Get1: 'FLPPBTGOF',
	TwentyOffSemi: 'TwentyOffSemi',

	// updated for the purchase plan
	b1g1Coupons: ['BOGOF', 'DoubleValue'],
	b2g1Coupons: ['FLPPBTGOF', 'BTGOF', 'cybermonday2022', 'TripleSavings'],
	percentOff: [
		'30q',
		'TwentyOffSemi',
		'P11763',
		'TwentyOff',
		'Hatchwise35',
		'P11788',
		'30OFF',
		'95jffuf89',
		'yYanxMue6',
		'247Artists',
		'hatchwise',
		'35off',
		'47525950',
		'blackfriday23',
		'quarterly15',
		'annually20',
		'joeignace',
		'Nalia35',
		'Nalia25',
		'ck',
		'qtr20savings',
		'Save15Qtr',
		'logo35',
		'troyiamonay',
		'qtr15savings',
		'js3heash32',
		'Hatchwise20',
		'30OFF',
		'10OFFHW',
		'10OFFLD'
	],
	discountOfTotalAmount: ['10OFF', 'referral100', 'valaunch', 'slidebean'],
};

// need to review this how can we make it more dynamic to avoid some static conditions
export const couponsDetail = {
	1: {
		Buy1Get1: { name: 'Buy1Get1', quantity: 1, type: 2 },
	},
	2: {
		Buy2Get1: {
			name: 'Buy2Get1',
			quantity: 2,
			type: 2,
		},
	},
};
